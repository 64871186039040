import React from "react";
import "../Navbar/Navbar.css";
import logo from "../Pages/images/logo.png";
import { Link } from "react-router-dom";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PeopleIcon from "@material-ui/icons/People";
import Home from "@material-ui/icons/Home"
import About from "@material-ui/icons/Receipt"

const Navbar = () => {
  return (
    <div>
      <div className="navbar navbar-expand-lg navbar-dark navs">
        <div className="container-fluid cont">
          <a className="navbar-brand" href="https://yourfirstad.com">
            <img className="img-fluid" src={logo} width="37vw" alt="" />
            <span className="ml-4">yourFirstAd</span>
          </a>
        </div>
        <button
          className="navbar-toggler ham__btn"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item nav-home">
              <Link className="nav-link text-white ml-4" to="/">
                <Home/>Home
              </Link>
            </li>
            <li className="nav-item nav-home">
            <Link className="nav-link text-white ml-4" to="/about">
              <About/>About
            </Link>
          </li>
            <li className="nav-item nav-signup">
              <Link className="nav-link text-white ml-4" to="/signup">
               <NoteAddIcon/> Signup
              </Link>
            </li>

            <li className="nav-item nav-login">
            <Link className="nav-link text-white ml-4" to="/login">
              <PeopleIcon/> Login
            </Link>
          </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
