import React, { useEffect, useState } from "react";
import { db } from "../../../Firebase";

import "./ComingDealsInterestedUser.scss";

// Displays table of interested users for the passed deal id
const ComingDealsInterestedUser = (props) => {
  const [interestedUser, setInterestedUsers] = useState([]);

  const dealDOCID = props.match.params.dealID;

  useEffect(() => {
    db.collection("coming-deal")
      .doc(dealDOCID)
      .onSnapshot((docs) => {
        if (docs.exists) {
          const { interestedUser } = docs.data();

          setInterestedUsers(interestedUser);
        } else {
        }
      });
  }, []);

  if (!dealDOCID) {
    return null;
  }

  return (
    <React.Fragment>
      <h4
        style={{ textAlign: "center", margin: "20px" }}
      >{`DEAL ID: ${dealDOCID}`}</h4>
      <table id="deals">
        <thead>
          <tr>
            <th>S.NO.</th>
            <th>DOC ID</th>
            <th>Email</th>
          </tr>
        </thead>
        <tfoot>
          {interestedUser.length === 0 ? <p>No user</p> : null}
          {interestedUser.map((data, index) => (
            <tr key={data.docID}>
              <td>{index + 1}</td>
              <td>{data.docID}</td>
              <td>{data.email}</td>
            </tr>
          ))}
        </tfoot>
      </table>
    </React.Fragment>
  );
};

export default ComingDealsInterestedUser;
