import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../Firebase";
import Loader from "../../Loader/Loader.";

import "../RequestedDeals.scss";

const DisplayRefCodesAdmin = () => {
  const [refCodeDocs, setRefCodeDocs] = useState([]);
  const tBodyRef = useRef();

  useEffect(() => {
    const unsub = db
      .collection("ref_codes")
      .get()
      .then((d) => {
        setRefCodeDocs([]);
        d.forEach((docc) => {
          let tempObj = {};
          db.collection("users")
            .doc(docc.id)
            .onSnapshot((dd) => {
              const { Name, Email, Phone_Number } = dd.data();
              tempObj = {
                Name,
                Email,
                Phone_Number,
                id: dd.id,
              };

              setRefCodeDocs((prev) => {
                return [...prev, tempObj];
              });
            });
        });
      });

    return unsub;
  }, []);

  return (
    <div className="container">
      <h5 className="m-4 ml-4">Referral Code details,</h5>
      {refCodeDocs.length === 0 && <Loader />}
      {refCodeDocs.length !== 0 && (
        <table id="deals">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>More Details</th>
            </tr>
          </thead>
          <tfoot ref={tBodyRef}>
            {refCodeDocs.map((refCode, index) => (
              <tr key={index}>
                <td>{refCode.Name}</td>
                <td>{refCode.Email}</td>
                <td>{refCode.Phone_Number}</td>
                <td>
                  <Link
                    className="btn btn-danger"
                    to={`/admin/ref-code-details/${refCode.id}?email=${refCode.Email}`}
                  >
                    click here
                  </Link>
                </td>
              </tr>
            ))}
          </tfoot>
        </table>
      )}
    </div>
  );
};

const ViewRefCodeDetails = (props) => {
  const headersData = ["Name", "Email", "Phone_Number", "verified user", "joined at"];

  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [refCode, setRefCode] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [noRefYet, setNoRefYet] = useState(false);

  useEffect(() => {
    setNoRefYet(false);
    setLoading(true);
    const docID = props.match.params.id;
    db.collection("ref_codes")
      .doc(docID)
      .onSnapshot(async (d) => {
        if (d.exists) {
          const { ref_code, referrals_applied } = d.data();

          setRefCode(ref_code);

          const refData = [];
          for (let i = 0; i < referrals_applied.length; i++) {
            await db
              .collection("users")
              .doc((referrals_applied[i].userid) || (referrals_applied[i].userDOCID) )
              .get()
              .then((dd) => {
                const { Name, Email, Phone_Number } = dd.data();
                const tempObj = {
                  Name: Name,
                  Email: Email,
                  Phone_Number: Phone_Number,
                  verifiedUser: referrals_applied[i].isVerified,
                  joinedAt: (referrals_applied[i].date || referrals_applied[i].signUpAt),
                };
                refData.push(tempObj);
              });
          }
          setFilteredRows(refData);
          setRowsData(refData);
          setLoading(false);
        } else {
          setLoading(false);
          setNoRefYet(true);
        }
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredRows(
        rowsData.filter((row) => {
          const d = row.Name + row.Email + row.Phone_Number;
          return d.toLowerCase().includes(search.toLowerCase());
        })
      );
    }, 50);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  return (
    <div className="mainContainer">
      <div className="filterContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="text-center text-secondary m-4">
              All users who have used this referral code will display here.
            </div>
            <div className="searchHeader">
              <input
                type="search"
                placeholder="search by name, email or state"
                onChange={(e) => setSearch(e.target.value)}
              />
              {refCode && (
                <>
                  <span>
                    Email:
                    <span className="text-primary">
                      {props.location.search.split("=")[1]}
                    </span>
                  </span>
                  <span>
                    Referral Code:{" "}
                    <span className="text-primary">{refCode}</span>
                  </span>
                </>
              )}
            </div>
            <div className="tableContainer">
              <table id="itemsTable">
                <thead>
                  <tr>
                    {headersData.map((headerName) => (
                      <th key={headerName}>{headerName}</th>
                    ))}
                  </tr>
                </thead>
                {filteredRows.length === 0 && !noRefYet && (
                  <p className="no-data">No data found :)</p>
                )}
                {filteredRows.length === 0 && noRefYet && (
                  <>
                    <p className="no-data">
                      This referral code is haven't used yet.
                    </p>
                  </>
                )}
                <tbody>
                  {filteredRows.map((row) => (
                    <tr key={row.Email}>
                      <td
                        style={{
                          borderLeftColor: row.color,
                        }}
                      >
                        {row.Name}
                      </td>
                      <td>{row.Email}</td>
                      <td>{row.Phone_Number}</td>
                      <td className="text-center">
                        {!row.verifiedUser ? "❌" : "✅"}
                      </td>
                      <td>
                        {new Date(
                          row.joinedAt.toString(0, 24)
                        ).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { DisplayRefCodesAdmin, ViewRefCodeDetails };
