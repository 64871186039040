import React from "react";

import "./BlogContent.scss";

// Main points images
import whatIsInf from "../../assets/influencer-01.jpg";
import whyInf from "../../assets/influencer-02.jpg";
import howToBecomeInf from "../../assets/influencer-03.jpg";

// Sub points images
import a from "../../assets/a.jpg";
import b from "../../assets/b.png";
import c from "../../assets/c.png";
import d from "../../assets/d.png";
import e from "../../assets/e.png";
import f from "../../assets/f.png";
import g from "../../assets/g.png";

const BlogContent = () => {
  return (
    <div className="blog-content">
      <div className="intro">
        <h1>How to become an Ideal Influencer for Influencer Marketing?</h1>
        <p>
          Communication does not merely entail words — they possess much more
          powerful tools, and one of them that brims with the most potential is
          influence. Influence is not just about the numbers, it is about the
          bond, the relationship you secure with people. Expressing yourself
          through varied means and connecting with various netizens will slowly
          lead to people noticing you. They'll begin to listen to and value your
          voice. The power of being listened to ultimately gives you the
          authority of influencing people's thoughts. That’s what makes you an
          influencer. But how do you know that you’re the person every brand
          wants to work with? How do you know you are the ideal influencer for
          influencer marketing? And on that note, what exactly is influencer
          marketing?
        </p>
      </div>

      <div className="main-points">
        <div className="point">
          <div className="left">
            <h2>What is Influencer Marketing?</h2>
            <p>
              Influencer Marketing is a kind of social media marketing that
              mainly focuses on driving brand message and awareness to a
              specific market of consumers. Simply, it uses endorsement of their
              product from influencers or individuals who have a stalwart social
              following and are viewed as experts within their vocation.
            </p>
            <p>
              Remember buying clothes that your favourite celebrities wear?
              Well, Influencer Marketing is very similar to this phenomenon. The
              only difference being Influencer Marketing is a well-structured
              and planned strategy to influence the target customers.
            </p>
            <p>
              To sum up, Influencer Marketing is a form of marketing where
              brands and companies hire social media influencers who have the
              expertise in their domain, to influence and attract their audience
              in order to convert them into potential customers.
            </p>
          </div>
          <div className="img-container">
            <img src={whatIsInf} alt="what is inf?" />
          </div>
        </div>
        <div className="point">
          <div className="left">
            <h2>Why Influencer Marketing?</h2>
            <p>
              Ads that keep interrupting you time and again in the e-world can
              become tiring to deal with. Ad blockers have been quite a help
              with this. But if people are not interested in ads, then how are
              brands going to reach them? That's where Influencer Marketing
              comes in. How does it work? Have a look.
            </p>
            <ul>
              <li>
                81% of millennial women say social media is the best way for
                brands to reach them.
              </li>
              <li>
                85% of pinners use Pinterest when they want to start a new
                project.
              </li>
              <li>
                6 in 10 millennials use Pinterest to discover new products
              </li>
              <li>
                8 in 10 people 18-49 years old use YouTube along with half of
                all internet users over the age of 75.{" "}
              </li>
              <li>
                90% of YouTubers discover new brands or products on YouTube. 40%
                of the shoppers around the globe say they have purchased
                something they discovered on YouTube.
              </li>
              <li>
                73% of teens say Instagram is the best way for brands to reach
                them.
              </li>
            </ul>
            <p>
              Many brands are investing a significant amount of time and
              resources into executing successful influencer marketing
              campaigns. Influencer Marketing is effective because it helps
              brands achieve campaign goals, generally categorized into two main
              divisions —brand awareness and direct response.
            </p>
          </div>
          <div className="img-container">
            <img src={whyInf} alt="why inf?" />
          </div>
        </div>
        <div className="point">
          <div className="left">
            <h2>How to become an ideal influencer for Influencer Marketing?</h2>
            <p>
              Before diving into the depth of this question, let’s take a quick
              look at the qualities a prominent influencer must possess.
            </p>
            <p>
              Being an influencer requires more sweat than glamour that appears
              on their profile. In order to be a successful Influencer, you need
              to work on these three traits:
            </p>
            <ul>
              <li>i. Hard-work</li>
              <li>ii. Creativity</li>
              <li>iii. A great deal of patience </li>
            </ul>
            <p>
              Building a loyal network of people that believe in you and support
              you isn’t something that's achieved in a day or two. It takes
              months of patience and constant efforts. The growing appeal of
              becoming an influencer has led to a competitive rise in content
              creators. Due to the diverse strategies of different individuals,
              it’s difficult to be able to stand out easily. However, following
              are some of the ways through which you can stand through the test
              of time.{" "}
            </p>
          </div>
          <div className="img-container">
            <img src={howToBecomeInf} alt="how to become inf?" />
          </div>
        </div>
      </div>

      <div className="main-points">
        <div className="point">
          <div className="left">
            <h2>1. Find your niche:</h2>
            <p>
              You need to have a deep understanding about what you’re doing.
              Your journey of expression must stem from the things that you're
              most passionate about. Ask yourself these questions to find out
              your true niche:
            </p>
            <ul>
              <li>What do I enjoy doing the most?</li>
              <li>
                What field can I thoroughly research without getting bored?
              </li>
              <li>What can I best express?</li>
            </ul>
            <p>
              It’s not necessary to stick to only one field. You can also merge
              two or more fields to provide a range of content to your viewers.
              But be sure to come up with reliable information. Don’t hesitate
              in experimenting and learning from them.
            </p>
          </div>
          <div className="img-container">
            <img src={a} alt="find niche" />
          </div>
        </div>

        <div className="point">
          <div className="left">
            <h2>2. Share your platform:</h2>
            <p>
              Do not hesitate in or miss the opportunity of exhibiting your
              creativity, whether online or offline. Let’s say you’re a makeup
              artist who is building a platform online, but hesitates to share
              this with your close ones. Chances are, your growth will be
              stubbed that way. Be vocal about your creation and share it with
              others every chance you get. Demonstrate your expertise boldly and
              without shame. A kickstart is the most important aspect of
              beginning your journey and getting further into influencer
              marketing. You can even go to different beauty salons and make a
              live video with them, thus spreading the word around, which might
              help in you being hired to promote other such parlours and brands.
            </p>
          </div>
          <div className="img-container">
            <img src={b} alt="share platform" />
          </div>
        </div>

        <div className="point">
          <div className="left">
            <h2>3. Audience engagement:</h2>
            <p>
              While social media is a platform where one can by chance become a
              star overnight, many can also get scrolled up without being
              noticed. Here are a few ways in which you can establish your
              influencing roots firmly —
            </p>
            <ul>
              <li>Post engaging content</li>
              <li>Engage with your audience</li>
              <li>Make reels</li>
              <li>Be up-to-date with trends</li>
              <li>Post polls </li>
              <li>Put daily stories</li>
              <li>Ask for feedback</li>
              <li>Hold contests</li>
              <li>Do giveaways</li>
              <li>Be consistent</li>
            </ul>
            <p>
              Maintaining a group of loyal and active audience is what most of
              the brands seek for. A large number of dead followers is of no
              use. For this, let your followers know that you acknowledge their
              existence. Keep interacting, go live once in a while, do giveaways
              and ask for feedback. This will help you get the idea about the
              taste of your audience so that you can shape your creation
              according to your audience's taste.
            </p>
          </div>
          <div className="img-container">
            <img src={c} alt="audience engagement" />
          </div>
        </div>

        <div className="point">
          <div className="left">
            <h2>4. Choose your platform wisely:</h2>
            <p>
              Amidst the crowded list of e-platforms like Instagram, Tiktok,
              Pinterest, Facebook, YouTube, start with the one with which you’re
              the most comfortable. However, avoid sticking to only that one.
              Use that as your main platform, but never miss the chance of
              expanding your reach by entering other platforms and sharing about
              your presence there. Brands seek a diverse range of exposure bases
              in which their product can be advertised.
            </p>
          </div>
          <div className="img-container">
            <img
              className="img-center"
              src={d}
              alt="choose your platform wisely"
            />
          </div>
        </div>

        <div className="point">
          <div className="left">
            <h2>5. Increase your reach:</h2>
            <p>
              Hashtags have an important role in expanding the reach of your
              post. Use hashtags that are to-the-point and relevant to your
              content. Make sure not to spam your audience with too many of
              them.
            </p>
          </div>
          <div className="img-container">
            <img src={e} alt="Increase your reach" />
          </div>
        </div>

        <div className="point">
          <div className="left">
            <h2>6. Identify your tier:</h2>
            <p>
              Not every influencer is the same, be it according to the number of
              followers or the type of content they create. It is always better
              to identify where you lie in this vast spectrum. Influencer tiers
              can be broken down as the following:
            </p>
            <ul>
              <li>Mega/Celebrity: 1M+ followers</li>
              <li>Macro: 300k-1M followers</li>
              <li>Mid-tier: 50k-300k followers</li>
              <li>Micro: 5k-50k followers</li>
              <li>Nano: 1k-5k followers</li>
            </ul>
            <p>
              The major concern here is not the number of followers but the
              engagement rates. According to the latest trends, it is seen that
              the engagement rates increase as we go down the tier. The simple
              reason being, the lesser the number of followers, the more devoted
              they are.
            </p>
            <p>
              Your focus should not solely be on increasing the number of
              followers, but extracting the maximum engagement from the ones
              you've. Brands rely on the rapport and bond between an influencer
              and the community they build around them, whether it be tight-knit
              or ever expanding.
            </p>
          </div>
          <div className="img-container">
            <img src={f} alt="Identify your tier" />
          </div>
        </div>

        <div className="point">
          <div className="left">
            <h2>7. Resonate the brand’s existing aesthetic:</h2>
            <p>
              Being an influencer, you have your own domain and brand value.
              Thus, when the client brand hires you to add value to their
              product, all you have to do is complement the existing aesthetic
              of that brand through your content and refrain from dominating
              over it. A connection between your content and the brand must be
              established in all possible ways to bring a tinge of authenticity
              in your presentation.
            </p>
          </div>
          <div className="img-container">
            <img src={g} alt="Resonate the brand’s existing aesthetic" />
          </div>
        </div>

        <div className="point">
          <div className="left">
            <h2>8. Tune up with the brand’s creative team:</h2>
            <p>
              Desist being picky about accepting and reviewing the creative
              team’s ideas. The campaign management team can in fact help you in
              proportioning your content. Don’t forget the main idea behind the
              entire drive, which is to convert your audience into potential
              customers of your client brand.
            </p>
          </div>
          <div className="img-container">
            <img src={e} alt="Tune up with the brand’s creative team" />
          </div>
        </div>

        <div className="point last">
          <div className="left">
            <h1>Conclusion:</h1>
            <p>
              Now that you are aware of what you’ve signed up for, strategize,
              plan and execute. Always keep track of your audience and try
              increasing your reach regularly. The key is to find your unique
              creative voice and use it to create a distinctively authentic
              image for yourself as an influencer. Believe in the power within
              you, and the world's faith in you will inevitably follow.
            </p>
          </div>
        </div>

        <div className="point last">
          <div className="left">
            <h2>Why does yourFirstAd support Influencer Marketing?</h2>
            <p>
              yourFirstAd provides a common platform for both marketers and
              influencers because we believe in the dynamic, futuristic headway
              that the fusion of influencing creativity and brands can bring in
              the e-commerce world. We acknowledge the potent potential within
              you as an influencer and aim to empower you to bring this change
              in the following ways —
            </p>
            <p>Opportunities we offer you:</p>
            <ul>
              <li>
                Easy access to brands who are seeking for compatible
                influencers.
              </li>
              <li>A well classified list of brands to choose from.</li>
              <li>
                A secure, transparent and hassle-free way to collaborate with
                brands.
              </li>
              <li>Exclusively lucrative opportunities and deals.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
