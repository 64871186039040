import React, { useRef, useState } from "react";
import firebase from "../../Firebase";

import "./mobile-number-verification.css";

const MobileNumberVerification = ({ setMbVerified, phone, setPhone }) => {
  const [confirmationResult, setConfirmationResult] = useState(null);
  const verifyButtonRef = useRef();
  const statusLabel = useRef();
  const otpBox = useRef();
  const otpVerifyButtonRef = useRef();
  const otpInpRef = useRef();
  const phoneNumRef = useRef();
  const setStatusLabel = (msg, textColor = "red") => {
    statusLabel.current.style.color = textColor;
    statusLabel.current.innerHTML = `***${msg}***`;
  };

  const verifyOTP = () => {
    const code = document.querySelector("#otp-str").value;

    if (!confirmationResult) {
      return;
    }

    confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("Success");
        verifyButtonRef.current.disabled = true;

        setStatusLabel("Verified", "green");
        setMbVerified(true);
        otpVerifyButtonRef.current.disabled = true;

        otpInpRef.current.disabled = true;

        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        setStatusLabel("Wrong OTP! Try again!");
      });
  };

  // For Recaptcha
  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("re-c", {
      size: "invisible",
      callback: (response) => {
        console.log(response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        verifyNumber();
      },
    });
  };

  const verifyNumber = (event) => {
    event.preventDefault();
    setUpRecaptcha();

    const num = document.querySelector("#phone").value;
    const phoneNumber = `+91${num}`;

    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        otpBox.current.style.display = "flex";
        verifyButtonRef.current.disabled = true;
        phoneNumRef.current.disabled = true;

        setStatusLabel("Check your mobile OTP has been sent!", "green");

        setConfirmationResult(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        verifyButtonRef.current.disabled = true;
        if (!statusLabel.current.innerHTML.includes("Wrong OTP! Try again!")) {
          setStatusLabel("Please refersh the page and try again!");
        }
      });
  };

  return (
    <div className="mobile-num-verification">
      <div className="main-div">
        <div id="re-c"></div>
        <>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Excluding +91"
            maxLength="10"
            value={phone}
            ref={phoneNumRef}
            onChange={(e) => setPhone(e.target.value)}
          />

          <button onClick={verifyNumber} type="button" ref={verifyButtonRef}>
            Sent OTP
          </button>
        </>
        <div style={{ display: "none" }} className="otpBox" ref={otpBox}>
          <input ref={otpInpRef} id="otp-str" type="text" placeholder="OTP" />
          <button ref={otpVerifyButtonRef} type="button" onClick={verifyOTP}>
            Verify
          </button>
        </div>
      </div>
      <p className="status-p" ref={statusLabel}></p>
    </div>
  );
};

export default MobileNumberVerification;
