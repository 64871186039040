import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { auth, db } from "../Firebase";
import img from "../Pages/images/b2.svg";
import { Link } from "react-router-dom";

import MobileNumberVerification from "../Pages/firebase-services/mobile-number-verification";

const Signup = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [influArea, setInfluArea] = useState([]);
  const [socialMediaFollower, setSocialMediaFollower] = useState("");
  const [state, setState] = useState("");
  const [refferal, setRefferal] = useState({ applied: false, value: "" });
  const [bio, setBio] = useState("");
  const [socialPlatform, setSocialPlatform] = useState(null);
  const [socialPlatformLink, setSocialPlatformLink] = useState("");
  const [socialMediaNotListed, setsocialMediaNotListed] = useState("");
  const [checkbox, setCheckbox] = useState("");
  const [isMobVerified, setMbVerified] = useState(false);
  const [refs, setRefs] = useState([]);

  const finalSumbitButtonRef = useRef();

  useEffect(() => {
    db.collection("ref_codes")
      .get()
      .then((docs) => {
        setRefs(docs.docs.map((d) => ({ id: d.id, ...d.data() })));
        // console.log("d");
      });
  }, []);

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setName("");
    setPhone("");
    setInfluArea("");
    setSocialMediaFollower("");
    setState("");
    setRefferal({ applied: false, value: "" });
    setBio("");
    setSocialPlatformLink("");
    setsocialMediaNotListed("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(socialPlatform);
    console.log(influArea);
    console.log(socialPlatformLink);
    if (influArea.length === 0) {
      alert("Please Select Your Influence Area");
      return;
    } else if (!socialPlatform) {
      alert("Please Select Your Social Platform");
      return;
    }

    auth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        var userData = auth.currentUser.uid;
        console.log(userData);

        db.collection("users")
          .doc(userData)
          .set({
            Email: email,
            Name: name,
            Phone_Number: phone,
            influencing_Area: influArea,
            socialPlatform: socialPlatform,
            socialMediaFollower: socialMediaFollower,
            socialPlatformLink,
            socialMediaNotListed,
            State: state,
            refferal: refferal,
            Bio: bio,
            user_id: userData,
            isVerified: false,
            userCreatedAt: new Date().toString(),
          })
          .then(() => {
            if (refferal.applied === true) {
              const doc_ = refs.filter((d) => d.ref_code === refferal.value)[0];
              const temp =
                doc_.referrals_applied.length === 0
                  ? []
                  : doc_.referrals_applied;
              temp.push({
                isVerified: false,
                date: new Date().toString(),
                userid: userData,
              });
              db.collection("ref_codes").doc(doc_.id).update({
                referrals_applied: temp,
              });
              auth.signOut().then(() => {});
            }
          })
          .catch((error) => {
            alert(error);
            console.log(error);
          });
      })
      .then(() => {
        emailVerification();
        e.target.reset();
        resetForm();
        props.history.push("/login");
      })
      .catch((error) => {
        alert(error);
        console.log(error);
        e.target.reset();
        resetForm();
      });
  };

  const emailVerification = () => {
    var user = auth.currentUser;

    user
      .sendEmailVerification()
      .then(function () {
        alert(
          "Veification Email Has been Sent to Your Email ! Please verify before logging in to the dashboard"
        );
      })
      .catch(function (error) {
        alert(error);
      });
  };

  return (
    <div style={{ background: "#3AB2D0" }} className="container-fluid">
      <Navbar />
      <div className="row">
        <div className="col-md-4 container">
          <img src={img} className="img-fluid mt-5" alt="" />
          <hr
            style={{ width: "50%", backgroundColor: "white", height: "0.5vw" }}
          />
          <p className="text-white">
            Enroll yourself in yourFirstAd today and — <br />
            <br />
            • Get promotional deals for various domains.
            <br />
            <br />
            • Earn a well-deserved emolument for the responsibilities you'll be
            assigned with.
            <br />
            <br />
            • Win extra points for being the one to introduce us to your
            friends.
            <br />
            <br />
            • Attain the best remunerative opportunities and other benefits that
            we provide to celebrate the influencer within you.
            <br />
          </p>
        </div>
        <div
          className="card text-center  card__main mb-5 mx-auto"
          style={{ marginTop: "5rem" }}
        >
          <div className="card-header text-white header__main">
            <h3>Sign Up</h3>
          </div>

          <form className="card-body" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                placeholder="Email Address"
                required
              ></input>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                placeholder="Password"
                required
              ></input>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                placeholder=" Name"
                required
              ></input>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Phone Number
              </label>
              <MobileNumberVerification
                setMbVerified={setMbVerified}
                setPhone={setPhone}
                phone={phone}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Areas of Influence
              </label>
              <select
                className="form-control"
                id="areas"
                onChange={(e) => {
                  if (e.target.value === "select") setInfluArea([]);
                  else {
                    if (influArea.length === 2)
                      setInfluArea((prev) => [e.target.value, prev[1]]);
                    else setInfluArea([e.target.value]);
                  }
                }}
                placeholder="areas"
                required
              >
                <option value="select">--select--</option>
                {influArea[1] !== "Fashion" && (
                  <option value="Fashion">Fashion</option>
                )}
                {influArea[1] !== "Lifestyle" && (
                  <option value="Lifestyle">Lifestyle</option>
                )}
                {influArea[1] !== "Food" && <option value="Food">Food</option>}
                {influArea[1] !== "Travel" && (
                  <option value="Travel">Travel</option>
                )}
                {influArea[1] !== "Music & Dance" && (
                  <option value="Music & Dance">Music & Dance</option>
                )}
                {influArea[1] !== "Fitness" && (
                  <option value="Fitness">Fitness</option>
                )}
                {influArea[1] !== "DIY(art & craft)" && (
                  <option value="DIY(art & craft)">DIY(art & craft)</option>
                )}
                {influArea[1] !== "Sports" && (
                  <option value="Sports">Sports</option>
                )}
                {influArea[1] !== "Finance" && (
                  <option value="Finance">Finance</option>
                )}
                {influArea[1] !== "News & Politics" && (
                  <option value="News & Politics">News & Politics</option>
                )}
                {influArea[1] !== "Gaming" && (
                  <option value="Gaming">Gaming</option>
                )}
                {influArea[1] !== "Cooking" && (
                  <option value="Cooking">Cooking</option>
                )}
                {influArea[1] !== "Comedy & Prank Challenges" && (
                  <option value="Comedy & Prank Challenges">
                    Comedy & Prank Challenges
                  </option>
                )}
                {influArea[1] !== "Photography" && (
                  <option value="Photography">Photography</option>
                )}
                {influArea[1] !== "Cosmetic & Beauty" && (
                  <option value="Cosmetic & Beauty">Cosmetic & Beauty</option>
                )}
              </select>
            </div>
            {influArea.length > 0 && (
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Areas of Influence(Choice 2) Optional
                </label>
                <select
                  className="form-control"
                  id="areas"
                  // value={influArea}
                  onChange={(e) => {
                    if (e.target.value === "select")
                      setInfluArea((prev) => [prev[0]]);
                    else {
                      if (influArea.length >= 2) {
                        // influArea.pop();
                        setInfluArea((prev) => [prev[0], e.target.value]);
                      } else {
                        setInfluArea((prev) => [...prev, e.target.value]);
                      }
                    }
                  }}
                  placeholder="areas"
                  required
                >
                  <option value="select">--select--</option>
                  {influArea[0] !== "Fashion" && (
                    <option value="Fashion">Fashion</option>
                  )}
                  {influArea[0] !== "Lifestyle" && (
                    <option value="Lifestyle">Lifestyle</option>
                  )}
                  {influArea[0] !== "Food" && (
                    <option value="Food">Food</option>
                  )}
                  {influArea[0] !== "Travel" && (
                    <option value="Travel">Travel</option>
                  )}
                  {influArea[0] !== "Music & Dance" && (
                    <option value="Music & Dance">Music & Dance</option>
                  )}
                  {influArea[0] !== "Fitness" && (
                    <option value="Fitness">Fitness</option>
                  )}
                  {influArea[0] !== "DIY(art & craft)" && (
                    <option value="DIY(art & craft)">DIY(art & craft)</option>
                  )}
                  {influArea[0] !== "Sports" && (
                    <option value="Sports">Sports</option>
                  )}
                  {influArea[0] !== "Finance" && (
                    <option value="Finance">Finance</option>
                  )}
                  {influArea[0] !== "News & Politics" && (
                    <option value="News & Politics">News & Politics</option>
                  )}
                  {influArea[0] !== "Gaming" && (
                    <option value="Gaming">Gaming</option>
                  )}
                  {influArea[0] !== "Cooking" && (
                    <option value="Cooking">Cooking</option>
                  )}
                  {influArea[0] !== "Comedy & Prank Challenges" && (
                    <option value="Comedy & Prank Challenges">
                      Comedy & Prank Challenges
                    </option>
                  )}
                  {influArea[0] !== "Photography" && (
                    <option value="Photography">Photography</option>
                  )}
                  {influArea[0] !== "Cosmetic & Beauty" && (
                    <option value="Cosmetic & Beauty">Cosmetic & Beauty</option>
                  )}
                </select>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Social Media Platform
              </label>
              <select
                className="form-control"
                id="platform"
                // value={influArea}
                onChange={(e) => {
                  if (e.target.value === "select") {
                    setSocialPlatform(null);
                    setSocialPlatformLink("");
                  } else setSocialPlatform(e.target.value);
                }}
                required
              >
                <option value="select" default>
                  --select--
                </option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Youtube">Youtube</option>
                <option value="Twitter">Twitter</option>
                <option value="Not Listed Platform">Others</option>
              </select>
            </div>

            {socialPlatform && (
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  {`If your preferred platform is not listed then, please specify`}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="originalLink"
                  value={socialMediaNotListed}
                  onChange={(e) => setsocialMediaNotListed(e.target.value)}
                  placeholder="Enter Platform Name"
                ></input>

                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  {`Profile Name/Link of your ${socialPlatform}'s account`}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="originalLink"
                  value={socialPlatformLink}
                  onChange={(e) => setSocialPlatformLink(e.target.value)}
                  placeholder="Enter Profile Usernaem/Link"
                  required
                ></input>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Social Media Handles{" "}
                <strong>
                  (follower/subscribers count must be between 100 to 15k)
                </strong>
              </label>
              <input
                type="number"
                className="form-control"
                id="followCount"
                value={socialMediaFollower}
                onChange={(e) => setSocialMediaFollower(e.target.value)}
                placeholder="Follower Count"
                min={100}
                max={15000}
                required
              ></input>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Select State
              </label>
              <select
                className="form-control"
                id="state"
                // value={state}
                onChange={(e) => setState(e.target.value)}
                required
              >
                <option value="select" default>
                  --select--
                </option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Refferal Code (Optional)
              </label>
              <input
                type="text"
                className="form-control"
                id="ref"
                value={refferal.value}
                onInput={(e) => {
                  const val = e.target.value.toUpperCase();

                  const temp = refs.some((d) => d.ref_code === val);

                  if (temp) {
                    setRefferal({ value: val, applied: true });
                  } else {
                    setRefferal({ value: val, applied: false });
                  }
                }}
                placeholder="Refferal code"
              />
              {refferal.value.length !== 0 ? (
                refferal.applied ? (
                  <p style={{ color: "green", marginTop: "10px" }}>
                    *Referral Code Applied*
                  </p>
                ) : (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    *Invalid Referral Code*
                  </p>
                )
              ) : (
                ""
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Tell us about yourself <strong>(less than 20 words)</strong>
              </label>
              <textarea
                type="text"
                className="form-control"
                id="bio"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder="Your Bio"
                required
              ></textarea>
            </div>

            <div className="form-check text-dark">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
                onChange={(e) => setCheckbox(e.target.value)}
              ></input>
              <label className="form-check-label" for="invalidCheck">
                Agree to our &nbsp;
                <a
                  className="text-primary"
                  href="https://yourfirstad.com/login/termsAndConditions.html"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                &nbsp; &amp;
                <a
                  className="text-primary"
                  href="https://yourfirstad.com/login/privacyPolicy.html"
                  target="_blank"
                >
                  &nbsp;Privacy Policy
                </a>
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
            <br />
            <button
              type="submit"
              disabled={!isMobVerified}
              ref={finalSumbitButtonRef}
              className="btn container text-white"
              style={{ backgroundColor: "#3AB2D0" }}
            >
              Submit
            </button>
            {!isMobVerified ? (
              <p style={{ fontSize: "14px", color: "red", marginTop: "10px" }}>
                ***Verify Mobile number first***
              </p>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
