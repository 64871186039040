import React, { useState } from 'react'
import { auth } from '../Firebase';
import Navbar from '../Navbar/Navbar';




const ForgotPassword = () => {

    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
var emailAddress = email;

auth.sendPasswordResetEmail(emailAddress).then(function() {
  // Email sent.
  
  alert("Email has been sent Successfully");
}).catch(function(error) {
  // An error happened.
  alert(error)
});
    }



    return (
        <div>
        <Navbar/>
        <div className="card text-center mx-auto mt-5  card__main_main mb-5">
        <div className="card-header text-white header__main"><h2>Forgot password</h2></div>
        
        <form className="card-body row" onSubmit={handleSubmit}>
      
        
        <div className="col-md-6 mx-auto">
        <div className="mb-3 mt-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">
          Email
        </label>
        <input
          type="Email"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="Email"
          placeholder="Email Address"
          required
        ></input>
      </div>
    


      <button type="submit" className="btn container text-white mt-5" style={{backgroundColor:"#3AB2D0"}}>
        Submit
      </button>
        </div>
       
        </form>
      </div>
        </div>
    )
}

export default ForgotPassword
