import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Home.css";
import Typewriter from "typewriter-effect";
import img1 from "../Pages/images/b3.svg";
import { Link } from "react-router-dom";
import Footer from "../Pages/Footer"
import imga1 from "../Pages/images/point1.svg"
import img2 from "../Pages/images/point2.svg"
import img3 from "../Pages/images/point3.svg"
import img4 from "../Pages/images/point4.svg"
import "../Pages/About.css"
import { FormatAlignCenter } from "@material-ui/icons";

const Home = () => {
  return (
    <div className="home__main">
      <Navbar />

      <div className="">
        <img
          className="header-custom img-fluid  "
          width="700vw"
          src={img1}
          alt=""
        ></img>
      </div>
      <div className="home__text text-white">
        <Typewriter
          className="type-writer"
          options={{
            strings: ["Start Influencing ...", "Be an Influencer !!", "Sign up Now ;)"],
            autoStart: true,
            loop: true,
          }}
        />
        <hr
          style={{ width: "50%", height: "0.5vw", backgroundColor: "#fff" }}
        />
        <br />
        <p className="container-fluid text-white">
          Are you a popular name amidst your diverse friend circle? Are you the
          colleague whose activities attract a lot of intrigue online? Unveil
          the potent influencer within you and let your skills acquire the
          opportunity to reap the optimum lucrative benefits!
        </p>

        <Link to="/signup">
          <button className="btn shopBtn">Sign Up</button>
        </Link>
      </div>
      <br />
        
        <br/>
        <div className="mx-auto h3 text-center d-flex align-items-center justify-content-center text-white" style={{ width:"60%" ,marginLeft: "80%"}}><h1>Why we?</h1></div>
      
       <br />
      <div className="mx-auto h3 text-center d-flex align-items-center justify-content-center text-white" style={{fontWeight: "200", fontSize: "21px",paddingBottom: "13px", width:"60%" ,marginLeft: "80%",paddingLeft: "13px", paddingright: "13px"}}>We've taken it upon ourselves to provide you with the most effortless but effective services in the following ways —</div>

      <div className="card mb-2 pros-card mx-auto" data-aos="fade-in-right" style={{maxWidth: "1040px"}}>

        <span className="pros-number">01</span>
        <div className="row g-0 row justify-content-center align-items-center p-4" style={{border: "0"}}>
          <div className="col-md-4">
            <img
              src={imga1}
              className="img-fluid imgid1"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body" className="props-para">
              <p className="card-text h4">
              • We facilitate you with easy access to brands ready to work with you.

              </p>
            </div>
          </div>
        </div>
      </div> <div className="card mb-2 pros-card mx-auto" data-aos="fade-in-right" style={{maxWidth: "1040px"}}>

      <span className="pros-number">02</span>
      <div className="row g-0 row justify-content-center align-items-center p-4" style={{border: "0"}}>
        <div className="col-md-4">
          <img
            src={img2}
            className="img-fluid imgid1"
            alt="..."
          />
        </div>
        <div className="col-md-8">
          <div className="card-body" className="props-para">
            <p className="card-text h4">
            • We curate for you a list of brands for you to peacefully make your desired choice.

            </p>
          </div>
        </div>
      </div>
    </div> <div className="card mb-2 pros-card mx-auto" data-aos="fade-in-right" style={{maxWidth: "1040px"}}>

    <span className="pros-number">03</span>
    <div className="row g-0 row justify-content-center align-items-center p-4" style={{border: "0"}}>
      <div className="col-md-4">
        <img
          src={img3}
          className="img-fluid imgid1"
          alt="..."
        />
      </div>
      <div className="col-md-8">
        <div className="card-body" className="props-para">
          <p className="card-text h4">
          • We provide you with a secure, transparent, and hassle-free way to collaborate with companies.

          </p>
        </div>
      </div>
    </div>
  </div> <div className="card mb-2 pros-card mx-auto" data-aos="fade-in-right" style={{maxWidth: "1040px"}}>

  <span className="pros-number">04</span>
  <div className="row g-0 row justify-content-center align-items-center p-4" style={{border: "0"}}>
    <div className="col-md-4">
      <img
        src={img4}
        className="img-fluid imgid1"
        alt="..."
      />
    </div>
    <div className="col-md-8">
      <div className="card-body" className="props-para">
        <p className="card-text h4">
        • There are various exclusive deals and rewarding offers that we will award you with from time to time.
        </p>
      </div>
    </div>
  </div>
</div>

<br />
      <div>
      <hr style={{backgroundColor:"white",height:"0.3rem",width:"80%" }}/>
      </div>
<br />
      <Footer/>
    </div>
  );
};

export default Home;
