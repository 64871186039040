import "./Loader.css";

const Loader = () => (
  <div className="loading-container">
    <div className="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

export default Loader;
