import React from "react";
import { Redirect, Route } from "react-router-dom";
import { auth } from "../../Firebase";

function ProtectedRoutesAdmin({ children, ...rest }) {
  const user = auth.currentUser;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          return children;
        } else {
          if(!localStorage.getItem('admin')){
          return (
            <Redirect to={{ pathname: "/admin-auth", state: { from: location } }} />
          )
          } else{
            return children
          }
        }
      }}
    />
  );
}

export default ProtectedRoutesAdmin;
