import React, { useEffect, useState } from "react";
import { auth, db } from "../../Firebase";

import "./Banner.scss";

const Banner = () => {
  const [deals, setDeals] = useState("FETCHING");
  const [userData, setUserData] = useState({ email: "", docID: "" });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserData({ email: user.email, docID: user.uid });

        db.collection("coming-deal").onSnapshot((docs) => {
          if (docs.docs.length !== 0) {
            const temp = docs.docs
              .map((comingDeal) => {
                const { showBanner, interestedUser } = comingDeal.data();
                if (showBanner) {
                  //  Check Current User Previously Interedted that Deal or not
                  let isCurrentUserIntereted = false;

                  if (typeof interestedUser !== typeof undefined) {
                    isCurrentUserIntereted =
                      interestedUser.filter((x) => x.docID == user.uid)
                        .length !== 0;
                  }

                  return {
                    ...comingDeal.data(),
                    isCurrentUserIntereted,
                    docDBID: comingDeal.id,
                  };
                } else {
                  return false;
                }
              })
              .filter(Boolean);

            setDeals(temp);
          } else {
            setDeals("FETCHED");
          }
        });
      }
    });
  }, []);

  return (
    <React.Fragment>
      {deals !== "FETCHING" && deals !== "FETCHED" && deals.length !== 0 ? (
        <React.Fragment>
          {console.log(deals)}
          <h2 style={{ textAlign: "center", paddingTop: "60px" }}>
            COMING DEALS
          </h2>
          <div className="banner-card-container">
            {deals.map((deal) => (
              <BannerCards
                key={deal.dealID}
                userObject={userData}
                dealObject={deal}
              />
            ))}
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const BannerCards = ({ userObject, dealObject }) => {
  if (!userObject || !dealObject) {
    return null;
  }

  const {
    poster,
    description,
    isCurrentUserIntereted,
    docDBID,
    interestedUser,
  } = dealObject;

  return (
    <div className="banner-container">
      <div className="banner">
        <header>Coming Deal</header>
        <section>
          <div className="image" style={{ textAlign: "center" }}>
            <img src={poster} alt="coming soon deal" />
          </div>
          <br />
          <br />
          <h6>
            <strong>About the Deal:</strong> {description}
          </h6>
        </section>
        <footer>
          <h8>
            <strong>Are you Interested ?</strong>
          </h8>
          <button
            className="btn m-5"
            disabled={isCurrentUserIntereted}
            onClick={() => {
              // Update interestedUser array at deal doc
              db.collection("coming-deal")
                .doc(docDBID)
                .update({
                  interestedUser: [...interestedUser, { ...userObject }],
                })
                .then(() => {
                  console.log("Updated");
                  // TODO:  Update current users doc if required

                  //Admin Nofitications
                  db.collection("admin-notifications")
                    .doc()
                    .set({
                      userDOCID: `users->${userObject.docID}`,
                      dealRef: `coming-deal->${docDBID}`,
                      dealType: "banner",
                      rendered: false,
                      timestamp: new Date().toString(),
                    })
                    .then(() => console.log("notification generated"))
                    .catch((err) => console.error(err));
                })
                .catch((err) => console.log(err));
            }}
          >
            {isCurrentUserIntereted
              ? "Thankyou for showing interest!"
              : "Yes, Inform me!"}
          </button>
        </footer>
      </div>
    </div>
  );
};
export default Banner;
