import { useEffect, useState } from "react";
import { db } from "../../../Firebase";
import "./Admin-Notifications";
import NotiCard from "./Noti-Cards/Noti-Cards";
import Pagination from "./Pagination";

const AdminNotifications = () => {
  const [notis, setNotis] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notisPerPage, setNotisPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const sortOnTimestamp = (notis, sortOn) => {
    return notis.sort((a, b) => {
      return (
        (new Date(a.timestamp.substring(0, 24)) -
          new Date(b.timestamp.substring(0, 24))) *
        sortOn
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    db.collection("admin-notifications").onSnapshot((d) => {
      setLoading(false);
      if (!d.empty) {
        setNotis(
          sortOnTimestamp(
            d.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
            -1
          )
        );
      }
    });
  }, []);

  //Get current posts
  const indexOfLastNoti = currentPage * notisPerPage;
  const indexOfFirstNoti = indexOfLastNoti - notisPerPage;
  const currentNotis = notis.slice(indexOfFirstNoti, indexOfLastNoti);

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container admin-notifications">
      <div className="d-flex align-items-center justify-content-between mb-4 mt-2">
        <h1 className="text-primary">Notifications</h1>
        <span className="text-danger">Current Page: {currentPage}</span>
        <span className="text-danger">Notifications/Page: {notisPerPage}</span>
        <span className="text-danger">
          Total Notifications: {!loading ? notis.length : "loading...."}
        </span>
      </div>

      {!loading ? (
        <>
          <ul className="list-group">
            {currentNotis.map((noti) => (
              <NotiCard key={noti.id} noti={noti} />
            ))}
          </ul>
          <Pagination
            notisPerPage={notisPerPage}
            totalNotis={notis.length}
            paginate={paginate}
          />
        </>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AdminNotifications;
