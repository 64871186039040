import React, { useState } from "react";
import "./CreateTask.scss";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { db, storage } from "../../Firebase";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateTask = () => {
  const classes = useStyles();
  const [category, setCategory] = useState("");
  const [img, setImg] = useState("");
  const [registered, setRegistered] = useState(false);
  const [details, setDetails] = useState({
    taskId: "",
    taskName: "",
    description: "",
    reward: "",
    deadline: "",
  });

  const dropdownChangeHandler = (event) => {
    setCategory(event.target.value);
  };

  const detailChangeHandler = (e) => {
    setDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const imgChangeHandler = (e) => {
    console.log(e.target.files);
    setImg(e.target.files[0]);
  };

  const uploadImage = async (file) => {
    try {
      const storageRef = storage.ref(`/Microimages/${img.name}`);
      const uploadedImageSnapshot = await storageRef.put(file);
      const downloadURL = await uploadedImageSnapshot.ref.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const url = await uploadImage(img);
      console.log({ url });
      if (!url) {
        alert("Image Upload Failed!!");
        return;
      }
      await db
        .collection(category)
        .doc(category.slice(0, 3).toUpperCase() + details.taskId)
        .set({
          imgUrl: url,
          taskName: details.taskName,
          description: details.description,
          reward: details.reward,
          deadline: details.deadline,
        })
        .then(() => {
          console.log("data added");
          setRegistered(true);
        })
        .catch((err) => console.log(err.message));

      setDetails({
        taskId: "",
        taskName: "",
        description: "",
        reward: "",
        deadline: "",
      });
      setTimeout(() => {
        setRegistered(false);
      }, 3000);
      setCategory("");
      setImg(null);
      e.target.reset();
    } catch (err) {}
  };

  return (
    <div className="create-task">
      <div
        className="create-task-form-container container d-flex flex-column align-items-center justify-content-start"
        style={{
          width: "100%",
          height: "80vh",
          marginTop: "40px",
        }}
      >
        <h1 style={{ marginBottom: "40px", fontSize: "26px" }}>
          CREATE A TASK
        </h1>

        <form id="create-task-form" onSubmit={submitHandler}>
          <label htmlFor="category">
            Category
            <br />
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                onChange={dropdownChangeHandler}
              >
                <MenuItem value={"Fashion"}>Fashion</MenuItem>
                <MenuItem value={"Lifestyle"}>Lifestyle</MenuItem>
                <MenuItem value={"Food"}>Food</MenuItem>
                <MenuItem value={"Travel"}>Travel</MenuItem>
                <MenuItem value={"Music & Dance"}>Music & Dance</MenuItem>
                <MenuItem value={"Fitness"}>Fitness</MenuItem>
                <MenuItem value={"DIY(art & craft)"}>DIY(art & craft)</MenuItem>
                <MenuItem value={"Sports"}>Sports</MenuItem>
                <MenuItem value={"Finance"}>Finance</MenuItem>
                <MenuItem value={"News & Politics"}>News & Politics</MenuItem>
                <MenuItem value={"Gaming"}>Gaming</MenuItem>
                <MenuItem value={"Cooking"}>Cooking</MenuItem>
                <MenuItem value={"Comedy & Prank Challenges"}>
                  Comedy & Prank Challenges
                </MenuItem>
                <MenuItem value={"Photography"}>Photography</MenuItem>
                <MenuItem value={"Cosmetic & Beauty"}>
                  Cosmetic & Beauty
                </MenuItem>
              </Select>
            </FormControl>
          </label>
          <div className="form-group">
            <label htmlFor="taskId">Task Unique Identification Number</label>
            <input
              type="text"
              className="form-control"
              id="taskUID"
              placeholder="Task UID (Ex: 001)"
              name="taskId"
              value={details.taskId}
              onChange={detailChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="taskName">Task Name</label>
            <input
              type="text"
              className="form-control"
              id="taskName"
              placeholder="Task Name"
              name="taskName"
              value={details.taskName}
              onChange={detailChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              placeholder="Description"
              name="description"
              value={details.description}
              onChange={detailChangeHandler}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="reward">Rewards</label>
            <input
              type="text"
              className="form-control"
              id="reward"
              placeholder="Reward"
              name="reward"
              value={details.reward}
              onChange={detailChangeHandler}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="deadline">Deadline</label>
            <input
              type="date"
              className="form-control"
              id="deadline"
              placeholder="Deadline"
              name="deadline"
              value={details.deadline}
              onChange={detailChangeHandler}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="image">Task Image</label>
            <br />
            <input
              type="file"
              id="inputGroupFile02"
              required
              onChange={imgChangeHandler}
            />
          </div>
          <button
            style={{ border: "none" }}
            type="submit"
            className="btn btn-primary"
          >
            Create
          </button>
          {registered && (
            <div className="alert alert-primary" role="alert">
              Task Successfully Registered!!
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateTask;
