import React, { useEffect, useState } from "react";
import { auth, db } from "../Firebase";
import Nav from "../Navbar/Nav";

import "./UserDeals.scss";

const UserDeals = () => {
  const [deals, setDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .where("Email", "==", user.email)
          .onSnapshot((snapshot) => {
            const DOCID = snapshot.docs[0].id;
            db.collection("requested-deals")
              .where("userDOCID", "==", DOCID)
              .onSnapshot((snapshot) => {
                if (snapshot.docs.length !== 0) {
                  const temp = snapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                  }));
                  setDeals(temp);
                }
                setIsLoading(false);
              });
          });
      }
    });
  }, []);

  return (
    <div className="UserDeals-container">
      <Nav />
      <div className="user-deals">
        {isLoading ? (
          <div className="msg">Loading...</div>
        ) : deals.length === 0 ? (
          <div className="msg">No deals</div>
        ) : (
          <div className="deals-container">
            <div className="msg">Your Deals</div>
            <div className="deals">
              {deals.map((d) => (
                <div
                  key={d.id}
                  className={`card${deals.length === 1 ? " single" : ""}`}
                >
                  <div className="image">
                    <img
                      src={
                        d.dealImg ||
                        "http://elsarcsenglish2.pbworks.com/f/task.jpg"
                      }
                      alt=""
                    />
                  </div>
                  <div className="title">Deal Name: {d.dealName}</div>
                  <div className="status"> Status: 
                    <span style={{ color: "salmon" }}> {d.status === "SENT" ? "Pending" : null}</span>
                    <span style={{ color: "red" }}> {d.status === "DECLINED" ? "Declined, Sorry It's expired." : null}</span>
                    <span style={{ color: "green" }}> {d.status === "ACCEPTED" ? "Approved, soon you will recieve the details in your mail." : null}</span>
                  </div>
                  <div className="about">Applied On: {d.requestedDateTime.substr(0, 24)}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDeals;
