import React, { useState } from "react";
import "../Login.css";
import Navbar from "../../Navbar/Navbar";
import { auth, db } from "../../Firebase";
import Loader from "react-loader-spinner";
import img from "../images/b4.svg";
import { Link } from "react-router-dom";

const AdminLogin = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);
    db.collection("admins")
      .where("email", "==", email)
      .get()
      .then((snap) => {
        setLoader(true);
        if (snap.docs.length === 0) {
          setLoader(false);
          alert("You are restricted");
          resetForm();
          return;
        }
        snap.docs.forEach((d) => {
          console.log(d.data());
          if (d.data().email === email) {
            auth
              .signInWithEmailAndPassword(email, password)
              .then(async (cred) => {
                setLoader(false);
                localStorage.setItem("admin", true);
                history.push("/admin");
                return;
              });
          }
        });
      })
      .catch((err) => console.log(err.message));
  };
  //   console.log(loader);
  return (
    <div
      style={{ backgroundColor: "#3AB2D0", height: "53rem" }}
      className="container-fluid"
    >
      <Navbar />
      {!loader ? (
        <div className="card text-center mx-auto mt-5  card__main_main mb-5">
          <div className="card-header text-white header__main">
            <h2>Admin Login</h2>
          </div>

          <form className="card-body row" onSubmit={handleSubmit}>
            <div className="col-md-5">
              <img src={img} className="img-fluid" alt="" width="500px" />
            </div>

            <div className="col-md-6 mx-auto">
              <div className="mb-3 mt-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Email
                </label>
                <input
                  type="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="Email"
                  placeholder="Email Address"
                  required
                ></input>
              </div>
              <div className="mb-3 mt-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="pass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                ></input>
              </div>

              <Link to="/forgot">
                <div className="text-left text-primary">Forgot password?</div>
              </Link>

              <button
                type="submit"
                className="btn container text-white mt-5"
                style={{ backgroundColor: "#3AB2D0" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="center">
          <Loader
            type="Audio"
            color="#fff"
            height={100}
            width={100}
            timeout={11000}
          />
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
