import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db, storage } from "../../../Firebase";

import "./ComingDeals.scss";

// Contains form which is stores the newly created deal into  Database
const CreateDeal = () => {
  const [des, setDes] = useState("");
  const [file, setFile] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const handlSubmit = (e) => {
    e.preventDefault();
    if (des.length !== 0 && file != null && showBanner.length !== 0) {
      document.querySelector(".loader").style.display = "grid";

      const fileName = file.name + new Date().toString();
      const uploadTask = storage.ref(`comingDeals/${fileName}`).put(file);
      uploadTask.on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          document.querySelector(
            "#loader-p"
          ).innerHTML = `Updating...${parseInt(percentage)}%`;
        },
        (error) => {
          console.log(error);
        },
        () => {
          // DOC ID
          const dealID = new Date().toString().substring(0, 24);

          storage
            .ref("comingDeals")
            .child(fileName)
            .getDownloadURL()
            .then((url) => {
              db.collection("coming-deal")
                .doc()
                .set({
                  dealID,
                  description: des,
                  showBanner: showBanner === "true" ? true : false,
                  poster: url,
                  interestedUser: [],
                })
                .then(() => {
                  console.log("Updated");
                  document.querySelector(".loader").style.display = "none";
                  window.location.reload();
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        }
      );
    }
  };

  return (
    <div>
      <form>
        <div class="form-group">
          <label>Task Description</label>
          <input
            type="text"
            required
            value={des}
            class="form-control"
            placeholder="Enter Description"
            onChange={(e) => setDes(e.target.value)}
            id="des"
          />
        </div>

        <div class="form-group">
          <label>Poster</label>
          <input
            accept="image/*"
            required
            onChange={(e) => setFile(e.target.files[0])}
            type="file"
            class="form-control-file"
          />
        </div>

        <label class="my-1 mr-2" for="inlineFormCustomSelectPref">
          Show Banner
        </label>
        <select
          required
          class="custom-select my-1 mr-sm-2"
          id="inlineFormCustomSelectPref"
          onChange={(e) => setShowBanner(e.target.value)}
        >
          <option selected>Choose...</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>

        <br />

        <button
          type="submit"
          onClick={(e) => handlSubmit(e)}
          class="btn btn-primary"
        >
          Submit
        </button>
      </form>

      <div className="loader">
        <p id="loader-p"></p>
      </div>
    </div>
  );
};

// Contains table which showcases the information all the created deals
const ComingDealsHelper = () => {
  const [comingDeals, setComingDeals] = useState([]);

  useEffect(() => {
    db.collection("coming-deal").onSnapshot((docs) => {
      if (docs.docs.length !== 0) {
        const temp = docs.docs.map((doc) => ({ docID: doc.id, ...doc.data() }));
        setComingDeals(temp);
      }
    });
  }, []);

  return (
    <table id="deals">
      <thead>
        <tr>
          <th>S.NO.</th>
          <th>Deal ID</th>
          <th>Description</th>
          <th>Show/Hide Deal</th>
          <th>Time</th>
          <th>Interest Users (Total)</th>
        </tr>
      </thead>
      <tfoot>
        {comingDeals.length === 0 ? <p>No Deals</p> : null}
        {comingDeals.map((data, index) => (
          <tr key={data.docID}>
            <td>{index + 1}</td>
            <td>{data.docID}</td>
            <td>{data.description.substring(0, 15) + "..."}</td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={() => {
                  db.collection("coming-deal")
                    .doc(data.docID)
                    .get()
                    .then((doc) => {
                      doc.ref
                        .update({
                          showBanner: !doc.data().showBanner,
                        })
                        .then(() => {
                          console.log("Updated");
                        });
                    })

                    .catch((err) => console.log(err));
                }}
              >
                {data.showBanner ? "Visible" : "Invisible"}
              </button>
            </td>
            <td>{data.dealID.substring(0, 24)}</td>
            <td>
              <Link
                className="interested-users-link"
                to={`/admin/coming-deals/interested-users/${data.docID}`}
              >
                SEE LIST ( {data.interestedUser.length} )
              </Link>
            </td>
          </tr>
        ))}
      </tfoot>
    </table>
  );
};

// Main Component which contains Header with two buttons Create Deal and All Deals
const ComingDeals = () => {
  const [createDeal, setCreateDeal] = useState(false);

  const activeButtonStyles = {
    background: "black",
    color: "white",
  };

  return (
    <div className="coming-deals">
      <div className="options">
        <button
          style={createDeal ? activeButtonStyles : null}
          onClick={() => setCreateDeal(true)}
        >
          Create Deal
        </button>
        <button
          style={!createDeal ? activeButtonStyles : null}
          onClick={() => setCreateDeal(false)}
        >
          All Deals
        </button>
      </div>

      <div className="output">
        {createDeal ? <CreateDeal /> : <ComingDealsHelper />}
      </div>
    </div>
  );
};

export default ComingDeals;
