import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { auth, db } from "../Firebase";
import Nav from "../Navbar/Nav";

import "./Notifications.scss";

const Notifications = () => {
  const [loading, isLoading] = useState(true);
  const [noti, setNoti] = useState([]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .where("Email", "==", user.email)
          .onSnapshot((snapshot) => {
            const DOCID = snapshot.docs[0].id;
            db.collection("requested-deals")
              .where("userDOCID", "==", DOCID)
              .onSnapshot((snapshot) => {
                if (snapshot.docs.length !== 0) {
                  const temp = snapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                  }));
                  console.log("ss");
                  setNoti(sortAndFilter(temp));
                }

                isLoading(false);
              });
          });
      }
    });
  }, []);

  const sortAndFilter = (temp) => {
    const filtered = temp.filter((t) => t.status !== "SENT");

    filtered.sort((a, b) => {
      return (
        new Date(b.statusChangedAt.substr(0, 24)) -
        new Date(a.statusChangedAt.substr(0, 24))
      );
    });

    return filtered;
  };

  return (
    <div className="notifications">
      <Nav />
      <div className="notifications-container">
        {loading ? (
          <div className="msg">Loading...</div>
        ) : noti.length === 0 ? (
          <div className="msg">No notifications</div>
        ) : (
          <div className="notifications-list">
            {noti.map((n) => (
              <div key={n.id} className="notification-item">
                <p>
                  Your request for {n.dealName} has been {n.status}.
                </p>
                <p>
                  <span>
                    <Moment fromNow>
                      {new Date(n.statusChangedAt.substr(0, 24))}
                    </Moment>
                  </span>
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default Notifications;
