import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Admin from "./Pages/Admin/Admin";

import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import UserDashboard from "./Pages/UserDashboard";
import ProtectedRoutes from "./ProtectedRoutes";
import MyProfile from "./Pages/MyProfile";
import Notifications from "./Pages/Notifications";
import UserDeals from "./Pages/UserDeals";
import ForgotPassword from "./Pages/ForgotPassword";
import About from "./Pages/About";
import AdminLogin from "./Pages/Admin/AdminLogin";
import ProtectedRoutesAdmin from "./Pages/Admin/ProtectedRouteAdmin";
import Blogs from "./Pages/Blogs";
import DisplayReferralCodes from "./Pages/DisplayReferralCodes/DisplayReferralCodes";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/about" exact component={About} />
        <Route path="/admin-auth" component={AdminLogin} />
        <Route path="/forgot" exact component={ForgotPassword}></Route>
        <ProtectedRoutesAdmin path="/admin">
          <Admin />
        </ProtectedRoutesAdmin>
        <ProtectedRoutes path="/user/dashboard" exact>
          <UserDashboard />
        </ProtectedRoutes>
        <ProtectedRoutes path="/Blogs" exact>
          <Blogs />
        </ProtectedRoutes>
        <ProtectedRoutes path="/user/profile" exact>
          <MyProfile />
        </ProtectedRoutes>
        <ProtectedRoutes path="/user/notifications" exact>
          <Notifications />
        </ProtectedRoutes>

        <ProtectedRoutes path="/user/your-deals" exact>
          <UserDeals />
        </ProtectedRoutes>

        <ProtectedRoutes path="/user/referrals-applied" exact>
          <DisplayReferralCodes />
        </ProtectedRoutes>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
