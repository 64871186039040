import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyATRxdHmLE99mGnVHs0IyDbmkqPC58vh-Y",
    authDomain: "yourfirstad-business.firebaseapp.com",
    databaseURL: "https://yourfirstad-business-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "yourfirstad-business",
    storageBucket: "yourfirstad-business.appspot.com",
    messagingSenderId: "806179722459",
    appId: "1:806179722459:web:42e1941bc369301b3528fe",
    measurementId: "G-DHMX5E4YME"
});

var db = firebaseApp.firestore();
var auth = firebase.auth();
var storage = firebase.storage();

firebase.auth().languageCode = "it";
firebase.auth().useDeviceLanguage();

export { db, auth, storage };
export default firebase;
