import React, { useEffect, useRef, useState } from "react";
import Nav from "../Navbar/Nav";
import PeopleIcon from "@material-ui/icons/People";

import "./MyProfile.scss";
import { auth, storage, db } from "../Firebase";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import { Link } from "react-router-dom";

const MyProfile = () => {
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [userData, setUserData] = useState([]);
  const [i, setI] = useState(<FileCopyIcon />);

  const buttonRef = useRef();
  const spanRef = useRef();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .where("Email", "==", user.email)
          .onSnapshot((snapshot) => {
            const doc = snapshot.docs[0];
            setUserData({ ...doc.data(), id: doc.id });
            setEmail(user.email);
          });
      }
    });
  }, []);

  const setEmailToState = (e) => {
    if (e.target.files.length !== 0) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleProfileInput = () => {
    // File selected
    if (file === null) {
      window.alert("Select picture!");
      return;
    } else if (file.type.startsWith("image/") === false) {
      window.alert("Profile format not supported!");
      return;
    }

    db.collection("users")
      .where("Email", "==", email)
      .get()
      .then((snapshot) => {
        const DOC = snapshot.docs[0].data();

        if (typeof DOC.DP_URL !== typeof undefined) {
          // Need to delete old image
          storage
            .refFromURL(DOC.DP_URL)
            .delete()
            .then(() => {
              //deleted
              uploadNew();
              console.log("Cover deleted");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          uploadNew();
        }
      });
  };

  const uploadNew = () => {
    // upload new one
    const storageRef = storage
      .ref()
      .child("MICRO USER DP")
      .child(file.name + Math.random().toString())
      .put(file);

    storageRef.on(
      "state_changed",
      (snap) => {
        buttonRef.current.disabled = true;
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        spanRef.current.innerHTML =
          Math.round(percentage).toString() + " % UPLOADED";
      },
      (err) => {},
      async () => {
        const url = await storageRef.snapshot.ref.getDownloadURL();

        spanRef.current.innerHTML = "DONE";

        setTimeout(() => {
          spanRef.current.innerHTML = "";
          buttonRef.current.disabled = false;
        }, 200);

        db.collection("users")
          .where("Email", "==", email)
          .get()
          .then((snapshot) => {
            snapshot.docs[0].ref.update({ DP_URL: url });
          });
      }
    );
  };

  return (
    <div
      style={{ backgroundColor: "#C8E9F9", width: "100%", paddingTop: "2rem" }}
    >
      <div className="container profile">
        <Nav />
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div key="" className="col">
            <div class="card mb-3" style={{ maxWidth: "540rem" }}>
              <div className="row g-0">
                <div className="col-md-4 img-div">
                  <div>
                    <input
                      type="file"
                      name="new-pic"
                      onInput={(e) => setEmailToState(e)}
                    />
                    <button
                      ref={buttonRef}
                      disabled={file === null ? true : false}
                      onClick={() => handleProfileInput()}
                    >
                      Change
                    </button>
                    <span ref={spanRef}></span>
                  </div>

                  <img
                    src={
                      userData.DP_URL ||
                      "https://qph.fs.quoracdn.net/main-qimg-2b21b9dd05c757fe30231fac65b504dd"
                    }
                    style={{ width: "20rem" }}
                    alt="..."
                    className="img-fluid"
                  ></img>
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h2
                      style={{
                        fontFamily: "Comic Sans MS, Comic Sans, cursive",
                      }}
                      className="card-title"
                    >
                      Hello {userData.Name}!
                    </h2>
                    <p
                      style={{
                        fontFamily: "Comic Sans MS, Comic Sans, cursive",
                      }}
                      className="card-text"
                    >
                      State: {userData.State}
                    </p>
                    <p className="text-muted">
                      Phone no: {userData.Phone_Number}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3" style={{ maxWidth: "540rem" }}>
              <div className="row g-0">
                <div className="col-md-12">
                  <div className="card-body">
                    <PeopleIcon /> &nbsp;{" "}
                    <span style={{ fontSize: "2rem" }}>About Me</span> <br />{" "}
                    <br />
                    <p
                      className="card-text"
                      style={{
                        fontFamily: "Comic Sans MS, Comic Sans, cursive",
                      }}
                    >
                      <span>Bio : </span>
                      {userData.Bio}
                    </p>{" "}
                    <br />
                    <span>
                      <h5>Refer your friend now!!</h5>
                    </span>
                    <div className="ref-code">
                      <button
                        onClick={() => {
                          const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                          var ref = userData.Name.substr(0, 3).toUpperCase();
                          for (let i = 0; i < 6; i++)
                            ref +=
                              str[Math.round(Math.random() * (str.length - 2))];

                          db.collection("users")
                            .where("Email", "==", email)
                            .get()
                            .then((snapshot) => {
                              snapshot.docs[0].ref.update({ ref_code: ref });
                              db.collection("ref_codes").doc(userData.id).set({
                                ref_code: ref,
                                referrals_applied: [],
                              });
                            });
                        }}
                        disabled={
                          typeof userData.ref_code !== typeof undefined
                            ? true
                            : false
                        }
                        className="btn"
                      >
                        Generate Referral Code
                      </button>
                      {typeof userData.ref_code !== typeof undefined ? (
                        <p className="generated-ref-code">
                          {userData.ref_code}
                          <button
                            onClick={(e) => {
                              const inp = document.createElement("textarea");
                              inp.textContent = userData.ref_code;
                              document.body.appendChild(inp);
                              var selection = document.getSelection();
                              var range = document.createRange();
                              document.execCommand("copy");
                              range.selectNode(inp);
                              selection.removeAllRanges();
                              selection.addRange(range);
                              console.log(
                                "copied",
                                document.execCommand("copy")
                              );
                              selection.removeAllRanges();
                              document.body.removeChild(inp);

                              setI(<DoneOutlineIcon />);
                              setTimeout(() => {
                                setI(<FileCopyIcon />);
                              }, 700);
                            }}
                          >
                            {i}
                          </button>
                        </p>
                      ) : null}
                    </div>
                    <p className="ref-applied-link">
                      Track your referral code{" "}
                      <Link to="/user/referrals-applied">Click here</Link>
                    </p>
                    <p
                      className="card-title"
                      style={{
                        fontFamily: "Comic Sans MS, Comic Sans, cursive",
                      }}
                    >
                      Other Details:
                    </p>
                    <p>
                      <span className="text-muted">
                        {" "}
                        Social Media Plateform
                      </span>{" "}
                      : {userData.socialPlatform}
                    </p>
                    <p>
                      <span className="te xt-muted">
                        Social Media Followers
                      </span>{" "}
                      : {userData.socialMediaFollower}
                    </p>
                    <p>
                      <span className="text-muted">Social Media Link</span> :{" "}
                      {userData.socialPlatformLink}
                    </p>
                    <p>
                      <span className="text-muted">Influencing Areas</span> :{" "}
                      {userData.influencing_Area
                        ? userData.influencing_Area
                            .toString()
                            .replace("", ",  ")
                            .substr(1)
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
// "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"
