import React from "react";
import Nav from "../Navbar/Nav";
import Typewriter from "typewriter-effect";
import "./Blog.css";
import img from "../Pages/images/image.jpg";
import BlogContent from "./BlogContent/BlogContent";

const Blogs = () => {
  return (
    <div className="home mx-auto">
      <Nav />
      <div className="home__a">
        <img
          className="img-fluid my-img mx-auto d-block"
          width="900rem"
          src={img}
          alt=""
        ></img>

        <div className="text text-white mx-auto">
          <h1 className="text-dark text" style={{ textAlign: "center" }}>
            Where good ideas find you
          </h1>
          <p className="text-dark text-center">
            Read new perspectives on just about any topic. Everyone’s welcome.
          </p>

          <br />
        </div>
      </div>
      <div className="card-section">
        <br />
        <br />
        <h2
          className="text-white float-right"
          style={{ marginRight: "20%", marginTop: "2%", fontWeight: "bold" }}
        >
          {" "}
          Featured Blogs
        </h2>
        <hr
          className="float-left"
          style={{
            width: "40%",
            height: "4rem",
            left: "5",
            backgroundColor: "#fff",
            marginLeft: "20%",
          }}
        />
        <br /> <br />
        <BlogContent />
      </div>
    </div>
  );
};

export default Blogs;
