import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../Firebase";

import "./RequestedDeals.scss";

export const SingleCategoryAllDeals = (props) => {
  const [dealType, setDealType] = useState("");
  const [dealStatus, setDealStatus] = useState(true);
  const [dealRequests, setDealRequests] = useState([]);

  useEffect(() => {
    const dealType = props.match.params.type;
    console.log(dealType);
    setDealType(dealType);

    db.collection(`${dealType}`).onSnapshot((docs) => {
      if (docs.docs.length !== 0) {
        //Deals Requested
        setDealRequests(
          docs.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
      setDealStatus(false);
    });
  }, []);

  return (
    <div className="specificTypeDeals">
      {dealStatus ? (
        <div className="msg">Loading...</div>
      ) : dealRequests.length === 0 ? (
        <div className="msg">No Deals Deals for '{dealType}'</div>
      ) : (
        <table id="deals">
          <thead>
            <tr>
              <th>#DealId</th>
              <th>DealName</th>
              <th>Deadline</th>
            </tr>
          </thead>
          <tfoot>
            {console.log(typeof dealRequests)}
            {dealRequests.map((data, index) => (
              <tr key={data.id}>
                <td>{data.id}</td>
                <td>{data.taskName}</td>
                <td>{data.deadline}</td>
              </tr>
            ))}
          </tfoot>
        </table>
      )}
    </div>
  );
};

export const AllDeals = () => {
  const dealTypes = [
    "Fashion",
    "Lifestyle",
    "Food",
    "Travel",
    "Music & Dance",
    "Fitness",
    "DIY(art & craft)",
    "Sports",
    "Finance",
    "News & Politics",
    "Gaming",
    "Cooking",
    "Comedy/Prank/Challenges",
    "Personal/Photographer",
    "Cosmetic/Beauty",
  ];

  return (
    <div className="requestedDeals">
      <div className="info-container">
        <h1>Welcome to All Deals Section</h1>
        <h4>
          Here you can view the details (such as dealId, deal name, it's
          deadline etc.) of all the deals you had created before
        </h4>
        <h6>
          Choose out the category under which you wish to see the details..
        </h6>
      </div>
      <div className="types">
        {dealTypes.map((dealType, index) => (
          <div key={index} className="link-div">
            <Link to={`/admin/all-deals/${dealType}`}>{dealType}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};
