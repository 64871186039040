import React from "react";
import { Link } from "react-router-dom";

import "./mynav.scss";

// icons
import LogoutIcon from "@material-ui/icons/Close";
import { auth } from "../Firebase";
import {
  Bookmark,
  Face,
  Home,
  NotificationImportant,
} from "@material-ui/icons";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import Blog from "@material-ui/icons/Pages";

class Nav extends React.Component {
  state = {
    collapsed: true,
  };

  toggleHamburger = () => {
    const currentState = this.state.collapsed;
    this.setState({ collapsed: !currentState });
  };

  render() {
    return (
      <div className="admin-container">
        <nav className={this.state.collapsed ? "" : "expanded"}>
          <div
            className={
              this.state.collapsed
                ? "hamburger-menu collapsed"
                : "hamburger-menu"
            }
            onClick={this.toggleHamburger}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>

          <div className="wrapper">
            <div className="menu">
              <div className="item">
                <Link to="/user/dashboard">
                  <Home /> <span>Home</span>
                </Link>
              </div>

              <div className="item">
                <Link to="/user/profile">
                  <Face /> <span>My profile</span>
                </Link>
              </div>

              <div className="item">
                <Link to="/user/notifications">
                  <NotificationImportant /> <span>Notifications</span>
                </Link>
              </div>

              <div className="item">
                <Link to="/user/referrals-applied">
                  <TrendingUpIcon /> <span>Referrals Applied </span>
                </Link>
              </div>

              <div className="item">
                <Link to="/Blogs">
                  <Blog /> <span>Blogs</span>
                </Link>
              </div>

              <div className="item">
                <Link to="/user/your-deals">
                  <Bookmark /> <span>Your Deals</span>
                </Link>
              </div>
              <div className="item">
                <Link
                  to="/login"
                  onClick={() => {
                    localStorage.removeItem("user");
                    auth.signOut();
                  }}
                >
                  <LogoutIcon /> <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Nav;
