import React from "react";
import { Link, Route, Switch } from "react-router-dom";

import "./Admin.scss";
import CreateTask from "./CreateTask";
import UsersInfo from "./UsersInfo";
import { AllDeals, SingleCategoryAllDeals } from "./AllDeals";
import { RequestedDeals, SpecificTypeDeals } from "./RequestedDeals";

// icons
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PeopleIcon from "@material-ui/icons/People";
import { EventNote, ViewList } from "@material-ui/icons";
import LogoutIcon from "@material-ui/icons/Close";
import ComingDeals from "./ComingDeals/ComingDeals";
import ComingDealsInterestedUser from "./ComingDealsInterestedUser/ComingDealsInterestedUser";
import UpdateIcon from "@material-ui/icons/Update";
import AdminNotifications from "./Notifications/Admin-Notifications";
import NotificationsNoneTwoToneIcon from "@material-ui/icons/NotificationsNoneTwoTone";
import {
  DisplayRefCodesAdmin,
  ViewRefCodeDetails,
} from "./DisplayRefCodes/DisplayRefCodesAdmin";

const AdminRoutes = () => (
  <Switch>
    <Route path="/admin/coming-deals" exact component={ComingDeals} />
    <Route
      path="/admin/coming-deals/interested-users/:dealID"
      component={ComingDealsInterestedUser}
    />
    <Route path="/admin/create-task" exact component={CreateTask} />
    <Route path="/admin/users-information" exact component={UsersInfo} />
    <Route path="/admin/requested-deals/:type" component={SpecificTypeDeals} />
    <Route path="/admin/requested-deals" component={RequestedDeals} />
    <Route path="/admin/all-deals/:type" component={SingleCategoryAllDeals} />
    <Route path="/admin/all-deals" component={AllDeals} />
    <Route path="/admin/notifications" component={AdminNotifications} />
    <Route path="/admin/ref-codes" component={DisplayRefCodesAdmin} />
    <Route path="/admin/ref-code-details/:id" component={ViewRefCodeDetails} />
  </Switch>
);

class Admin extends React.Component {
  state = {
    collapsed: true,
  };

  toggleHamburger = () => {
    const currentState = this.state.collapsed;
    this.setState({ collapsed: !currentState });
  };

  render() {
    return (
      <>
        <div className="admin-container">
          <nav className={this.state.collapsed ? "" : "expanded"}>
            <div
              className={
                this.state.collapsed
                  ? "hamburger-menu collapsed"
                  : "hamburger-menu"
              }
              onClick={this.toggleHamburger}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </div>

            <div className="wrapper">
              <div className="menu">
                <div className="item">
                  <Link to="/admin/all-deals">
                    <ViewList /> <span>All Deals</span>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/admin/coming-deals">
                    <UpdateIcon /> <span>Coming Deals</span>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/admin/create-task">
                    <NoteAddIcon /> <span>Create Task</span>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/admin/users-information">
                    <PeopleIcon /> <span>Users Info</span>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/admin/requested-deals">
                    <EventNote /> <span>Requested Deals</span>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/admin/ref-codes">
                    <TrendingUpIcon /> <span>Referral Codes</span>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/admin/notifications">
                    <NotificationsNoneTwoToneIcon /> <span>Notifications</span>
                  </Link>
                </div>

                <div className="item">
                  <Link
                    to="/admin-auth"
                    onClick={() => localStorage.removeItem("admin")}
                  >
                    <LogoutIcon /> <span>Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </nav>

          <div className="pages">
            <AdminRoutes />
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
