import { useEffect, useState } from "react";
import { auth, db } from "../../Firebase";
import Nav from "../../Navbar/Nav";
import Loader from "../Loader/Loader.";
import "./DisplayReferralCodes.scss";

const DisplayReferralCodes = () => {
  const headersData = ["Name", "Email", "State", "verified user", "joined at"];

  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [refCode, setRefCode] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [noRefYet, setNoRefYet] = useState(false);

  useEffect(() => {
    // db.collection("ref_codes")
    //   .doc("9p1DT1wYWCZpqlaycxrAFQIq05c2")
    //   .get()
    //   .then((d) => {
    //     const temp = [];

    //     for (let i = 0; i < 1; i++) {
    //       temp.push({
    //         isVerified: false,
    //         signUpAt: "Sat Jul 10 2021 21:46:35 GMT+0530 (India Standard Time)",
    //         userDOCID: "rRJ760Mcu0TXi52vdUS3XTI5mv53",
    //       });
    //     }

    //     d.ref
    //       .update({
    //         referrals_applied: temp,
    //       })
    //       .then(() => console.log("hs"))
    //       .catch((err) => console.log(err));
    //   });

    auth.onAuthStateChanged((user) => {
      if (user) {
        setNoRefYet(false);
        setLoading(true);
        db.collection("ref_codes")
          .doc(user.uid)
          .onSnapshot(async (d) => {
            if (d.exists) {
              const { ref_code, referrals_applied } = d.data();

              setRefCode(ref_code);

              const refData = [];
              for (let i = 0; i < referrals_applied.length; i++) {
                await db
                  .collection("users")
                  .doc(referrals_applied[i].userDOCID)
                  .get()
                  .then((dd) => {
                    const { Name, Email, State } = dd.data();
                    const tempObj = {
                      name: Name,
                      email: Email,
                      state: State,
                      verifiedUser: referrals_applied[i].isVerified,
                      joinedAt: referrals_applied[i].signUpAt,
                    };
                    console.log(tempObj);
                    refData.push(tempObj);
                  });
              }
              setFilteredRows(refData);
              setRowsData(refData);
              setLoading(false);
            } else {
              setLoading(false);
              setNoRefYet(true);
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredRows(
        rowsData.filter((row) => {
          const d = row.name + row.email + row.state;
          return d.toLowerCase().includes(search.toLowerCase());
        })
      );
    }, 50);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  return (
    <div className="mainContainer">
      <Nav />
      <div className="filterContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="text-center text-secondary m-4">
              All users who have used your referral code will display here.
            </div>
            <div className="searchHeader">
              <input
                type="search"
                placeholder="search by name, email or state"
                onChange={(e) => setSearch(e.target.value)}
              />
              {refCode && (
                <span>
                  Referral Code: <span className="text-primary">{refCode}</span>
                </span>
              )}
            </div>
            <div className="tableContainer">
              <table id="itemsTable">
                <thead>
                  <tr>
                    {headersData.map((headerName) => (
                      <th key={headerName}>{headerName}</th>
                    ))}
                  </tr>
                </thead>
                {filteredRows.length === 0 && !noRefYet && (
                  <p className="no-data">No data found :)</p>
                )}
                {filteredRows.length === 0 && noRefYet && (
                  <>
                    <p className="no-data">
                      Your referral code is haven't used yet.
                    </p>
                  </>
                )}
                <tbody>
                  {filteredRows.map((row) => (
                    <tr key={row.email}>
                      <td
                        style={{
                          borderLeftColor: row.color,
                        }}
                      >
                        {row.name}
                      </td>
                      <td>{row.email}</td>
                      <td>{row.state}</td>
                      <td className="text-center">
                        {!row.verifiedUser ? "❌" : "✅"}
                      </td>
                      <td>
                        {new Date(
                          row.joinedAt.toString(0, 24)
                        ).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DisplayReferralCodes;
