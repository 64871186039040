import { useEffect, useState } from "react";
import { db } from "../../../../Firebase";

const NotiCard = ({ noti }) => {
  const [dealData, setDealData] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!noti.dealRef || !noti.userDOCID) return;

    setLoading(true);

    const dealPath = noti.dealRef.split("->");
    const userPath = noti.userDOCID.split("->");

    db.collection(dealPath[0])
      .doc(dealPath[1])
      .onSnapshot((doc) => {
        setDealData(doc.data());
      });

    db.collection(userPath[0])
      .doc(userPath[1])
      .onSnapshot((doc) => {
        setUserData(doc.data());
        setLoading(false);
      });
  }, []);

  return (
    <li className="list-group-item mb-4 d-flex align-items-center">
      {loading && <div>Loading....</div>}
      {!loading && (
        <>
          <span className="text-primary">{userData.Email}</span>
          <span className="text-muted ml-1 mr-1">has requested for</span>
          <p
            className="text-primary"
            style={{
              display: "inline-block",
              maxWidth: "400px",
              textDecoration: "none",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textAlign: "center",
              margin: "0",
              fontFamily: "inherit",
            }}
          >
            {dealData.dealName || dealData.description}{" "}
          </p>
          <span className="text-muted ml-1 mr-1">at</span>
          <span className="text-danger ml-1 mr-1">
            {noti.timestamp.substring(0, 24)}
          </span>{" "}
        </>
      )}
    </li>
  );
};

export default NotiCard;
