import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../Firebase";

import Moment from "react-moment";

import "./RequestedDeals.scss";
import swal from "sweetalert";

console.log();

export const SpecificTypeDeals = (props) => {
  const [dealType, setDealType] = useState("");
  const [dealStatus, setDealStatus] = useState(true);
  const [dealRequests, setDealRequests] = useState([]);

  useEffect(() => {
    const dealType = props.match.params.type;
    setDealType(dealType);

    db.collection("requested-deals")
      .where("dealType", "==", dealType)
      .where("status", "==", "SENT")
      .onSnapshot((docs) => {
        if (docs.docs.length !== 0) {
          //Deals Requested
          setDealRequests(
            docs.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        }
        setDealStatus(false);
      });
  }, []);

  return (
    <div className="specificTypeDeals">
      {dealStatus ? (
        <div className="msg">Loading...</div>
      ) : dealRequests.length === 0 ? (
        <div className="msg">No Requested Deals for '{dealType}'</div>
      ) : (
        <table id="deals">
          <thead>
            <tr>
              <th>#</th>
              <th>Requested Time</th>
              <th>Deal Name</th>
              <th>Deal Type</th>
              <th>User Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tfoot>
            {console.log(typeof dealRequests)}
            {dealRequests.map(
              (
                {
                  requestedDateTime,
                  status,
                  id,
                  dealType,
                  dealName,
                  userName,
                },
                index
              ) => (
                <tr key={id}>
                  <td>{index + 1}</td>
                  <td>
                    <Moment fromNow>{new Date(requestedDateTime)}</Moment>
                  </td>
                  <td>{dealName}</td>
                  <td>{dealType}</td>
                  <td>{userName}</td>
                  <td>
                    {status === "SENT" ? (
                      <>
                        <button
                          className="decision-btn"
                          onClick={() => {
                            swal({
                              text: "Accept request?",
                              buttons: {
                                cancel: true,
                                confirm: true,
                              },
                            }).then((status) => {
                              if (status === true) {
                                //Change Status

                                db.collection("requested-deals")
                                  .doc(id)
                                  .update({
                                    status: "ACCEPTED",
                                    statusChangedAt: new Date().toString(),
                                  })
                                  .then(() => {})
                                  .catch((err) => console.log(err));
                                swal({
                                  closeOnClickOutside: true,
                                  icon: "success",
                                  button: false,
                                  timer: 1500,
                                });
                              }
                            });
                          }}
                        >
                          Accept
                        </button>

                        <button
                          className="decision-btn"
                          onClick={() => {
                            swal({
                              text: "Decline request?",
                              buttons: {
                                cancel: true,
                                confirm: true,
                              },
                            }).then((status) => {
                              if (status === true) {
                                //Change Status

                                db.collection("requested-deals")
                                  .doc(id)
                                  .update({
                                    status: "DECLINED",
                                    statusChangedAt: new Date().toString(),
                                  })
                                  .then(() => {})
                                  .catch((err) => console.log(err));
                                swal({
                                  closeOnClickOutside: true,
                                  icon: "success",
                                  button: false,
                                  timer: 1500,
                                });
                              }
                            });
                          }}
                        >
                          Decline
                        </button>
                      </>
                    ) : null}
                  </td>
                </tr>
              )
            )}
          </tfoot>
        </table>
      )}
    </div>
  );
};

export const RequestedDeals = () => {
  const dealTypes = [
    "Fashion",
    "Lifestyle",
    "Food",
    "Travel",
    "Music & Dance",
    "Fitness",
    "DIY(art & craft)",
    "Sports",
    "Finance",
    "News & Politics",
    "Gaming",
    "Cooking",
    "Comedy & Prank Challenges",
    "Photography",
    "Cosmetic & Beauty",
  ];

  return (
    <div className="requestedDeals">
      <div className="info-container">
        <h1>Welcome to Requested Deals Section</h1>
        <h4>
          Here you can view the details of all the deals users requested you to
          assign
        </h4>
        <h6>
          Choose out the category under which you wish to see the details..
        </h6>
      </div>
      <div className="types">
        {dealTypes.map((dealType, index) => (
          <div key={index} className="link-div">
            <Link to={`/admin/requested-deals/${dealType}`}>{dealType}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};
