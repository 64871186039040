import React from "react";
import Navbar from "../Navbar/Navbar";
import img1 from "../Pages/images/point1.svg";
import img2 from "../Pages/images/point2.svg";
import img3 from "../Pages/images/point3.svg";
import img4 from "../Pages/images/point4.svg";
import "../Pages/About.css";

const About = () => {
  return (
    <div
      style={{ backgroundColor: "#3AB2D0", height: "auto" }}
      className="container-fluid"
    >
      <Navbar></Navbar>
      <br />
      <div
        className="mx-auto h3 text-center d-flex align-items-center justify-content-center text-white"
        style={{
          fontWeight: "100",
          fontSize: "21px",
          paddingBottom: "13px",
          width: "60%",
          marginLeft: "80%",
        }}
        id="heading-five"
      >
        We've taken it upon ourselves to provide you with the most effortless
        but effective services in the following ways —
      </div>
      <div
        className="card mb-2 pros-card mx-auto"
        data-aos="fade-in-right"
        style={{ maxWidth: "1040px" }}
      >
        <span className="pros-number">01</span>
        <div
          className="row g-0 row justify-content-center align-items-center p-4"
          style={{ border: "0" }}
        >
          <div className="col-md-4">
            <img src={img1} className="img-fluid imgid1" alt="fluid img" />
          </div>
          <div className="col-md-8">
            <div className="card-body" className="props-para">
              <p className="card-text h4">
                • We facilitate you with easy access to brands ready to work
                with you.
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className="card mb-2 pros-card mx-auto"
        data-aos="fade-in-right"
        style={{ maxWidth: "1040px" }}
      >
        <span className="pros-number">02</span>
        <div
          className="row g-0 row justify-content-center align-items-center p-4"
          style={{ border: "0" }}
        >
          <div className="col-md-4">
            <img src={img2} className="img-fluid imgid1" alt="next point" />
          </div>
          <div className="col-md-8">
            <div className="card-body" className="props-para">
              <p className="card-text h4">
                • We curate for you a list of brands for you to peacefully make
                your desired choice.
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className="card mb-2 pros-card mx-auto"
        data-aos="fade-in-right"
        style={{ maxWidth: "1040px" }}
      >
        <span className="pros-number">03</span>
        <div
          className="row g-0 row justify-content-center align-items-center p-4"
          style={{ border: "0" }}
        >
          <div className="col-md-4">
            <img src={img3} className="img-fluid imgid1" alt="..." />
          </div>
          <div className="col-md-8">
            <div className="card-body" className="props-para">
              <p className="card-text h4">
                • We provide you with a secure, transparent, and hassle-free way
                to collaborate with companies.
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className="card mb-2 pros-card mx-auto"
        data-aos="fade-in-right"
        style={{ maxWidth: "1040px" }}
      >
        <span className="pros-number">04</span>
        <div
          className="row g-0 row justify-content-center align-items-center p-4"
          style={{ border: "0" }}
        >
          <div className="col-md-4">
            <img src={img4} className="img-fluid imgid1" alt="..." />
          </div>
          <div className="col-md-8">
            <div className="card-body" className="props-para">
              <p className="card-text h4">
                • There are various exclusive deals and rewarding offers that we
                will award you with from time to time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default About;
