import React, { useState, useEffect } from "react";
import "./RequestedDeals.scss";
import "./UsersInfo.scss";
import { db } from "../../Firebase";

const UserInfo = () => {
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState(true);
  const [searchedText, setSearchedText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    db.collection("users").onSnapshot((docs) => {
      if (docs.docs.length !== 0) {
        const temp = docs.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setUsers(temp);
        setFilteredUsers(temp);
      }
      setStatus(false);
    });
  }, []);

  // Filter logic
  useEffect(() => {
    // Edge Case if nothing is searched then no need to filter
    if (searchedText.length === 0) {
      setFilteredUsers(users);
      return;
    }

    const timer = setTimeout(async () => {
      const filtered = users.filter((user) => {
        const { Email, Name, Phone_Number } = user;

        // Check value of input with all the three variables
        const checkEmail = Email.toLowerCase().includes(searchedText);
        const checkName = Name.toLowerCase().includes(searchedText);
        const checkPhoneNumber = Phone_Number.toString().includes(searchedText);

        // If any of the above is true filter that user
        if (checkEmail || checkName || checkPhoneNumber) {
          return user;
        }
      });

      setFilteredUsers(filtered);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchedText]);

  useEffect(() => {
    console.log(filter === "date-asc");
    setFilteredUsers([
      ...filteredUsers.sort((d1, d2) => {
        if (filter === "date-asc") {
          return (
            new Date(d2.userCreatedAt.substring(0, 24)) -
            new Date(d1.userCreatedAt.substring(0, 24))
          );
        } else if (filter === "date-desc") {
          return (
            new Date(d1.userCreatedAt.substring(0, 24)) -
            new Date(d2.userCreatedAt.substring(0, 24))
          );
        } else {
          return (
            parseInt(d1.socialMediaFollower) > parseInt(d2.socialMediaFollower)
          );
        }
      }),
    ]);
  }, [filter]);

  return (
    <div className="specificTypeDeals">
      <h1 className="user-info-header">Users Record</h1>

      {status ? (
        <div className="msg">Loading...</div>
      ) : users.length === 0 ? (
        <div className="msg">No Registered Users'</div>
      ) : (
        <React.Fragment>
          <div className="header-row">
            <input
              type="text"
              placeholder="Search by Email, Name, or Phone Number"
              onChange={(e) => {
                setSearchedText(e.target.value.toLowerCase());
              }}
            />

            <div className="filters">
              <label for="filters">Filter: </label>

              <select
                onClick={(e) => {
                  setFilter(e.target.value);
                }}
                name="filters"
                id="filters"
              >
                <option value="date-desc">Oldest to Newest</option>
                <option value="date-asc">Newest to Oldest</option>
              </select>
            </div>

            <div className="total-users">
              Total Users : {filteredUsers.length}
            </div>
          </div>

          <table id="deals">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Influencing Area</th>
                <th>Signed Up at</th>
                <th>UserName</th>
                <th>Platform</th>
                <th>State</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Followers Count</th>
                <th>Page Name</th>
              </tr>
            </thead>
            <tfoot>
              {filteredUsers.map((data, index) => (
                <tr key={data.id}>
                  <td>{index + 1}</td>
                  <td style={{ minWidth: "200px" }}>
                    {data.influencing_Area[0]}, {data.influencing_Area[1]}
                  </td>
                  <td style={{ minWidth: "230px" }}>
                    {data.userCreatedAt
                      ? data.userCreatedAt.substring(0, 24)
                      : "-"}
                  </td>
                  <td style={{ minWidth: "180px" }}>{data.Name}</td>
                  <td>{data.socialPlatform}</td>
                  <td style={{ minWidth: "180px" }}>{data.State}</td>
                  <td>{data.Phone_Number}</td>
                  <td>{data.Email}</td>
                  <td>{data.socialMediaFollower}</td>
                  <td>{data.socialPlatformLink}</td>
                </tr>
              ))}
              {filteredUsers.length === 0 ? "Nothing Found" : null}
            </tfoot>
          </table>
        </React.Fragment>
      )}
    </div>
  );
};

export default UserInfo;
