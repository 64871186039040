import React, { useState } from "react";
import "./Login.css";
import Navbar from "../Navbar/Navbar";
import { auth, db } from "../Firebase";
import Loader from "react-loader-spinner";
import img from "../Pages/images/b4.svg";
import { Link } from "react-router-dom";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then(async (cred) => {
        setLoader(true);
        if (cred) {
          //check if email is not verified
          if (!cred.user.emailVerified) {
            setLoader(false);
            alert("Please verify your email first!!!");
            resetForm();
            return;
          }

          //get user from collection
          const dbUser = await db.collection("users").doc(cred.user.uid);
          const userData = await dbUser.get();
          const user = userData.data();
          console.log(user);

          if (user.refferal.applied === true) {
            db.collection("ref_codes")
              .where("ref_code", "==", user.refferal.value)
              .get()
              .then((snap) => {
                const data = snap.docs[0].data().referrals_applied;

                const filtered = data.filter(
                  (d) => d.userDOCID === cred.user.uid
                )[0];
                const temp = {
                  signUpAt: filtered.signUpAt,
                  userDOCID: filtered.userDOCID,
                  isVerified: true,
                };

                const rem = data.filter((d) => d.userDOCID !== cred.user.uid);

                snap.docs[0].ref.update({
                  referrals_applied: [...rem, temp],
                });

                history.push("/user/dashboard");
              });
          }

          if (!user.isVerified && cred.user.emailVerified) {
            dbUser
              .update({
                isVerified: true,
              })
              .then(() => {

                // console.log("Verified is set to true!");
                setLoader(false)
                history.push("/user/dashboard");
              })
              .catch((err) => console.log(err));
          } else {
            localStorage.setItem('user',true);
            setLoader(false)
            history.push("/user/dashboard");
          }
          // redirect to dashboard
        }
      })
      .catch((error) => {
        setLoader(false)
        alert("User not registered");
        resetForm();
        console.log(error);
      });
  };
  // console.log(loader);
  return (
    <div
      style={{ backgroundColor: "#3AB2D0", height: "53rem" }}
      className="container-fluid"
    >
      <Navbar />
      {!loader ? (
        <div className="card text-center mx-auto mt-5  card__main_main mb-5">
          <div className="card-header text-white header__main">
            <h2>Login</h2>
          </div>

          <form className="card-body row" onSubmit={handleSubmit}>
            <div className="col-md-5">
              <img src={img} className="img-fluid" alt="" width="500px" />
            </div>

            <div className="col-md-6 mx-auto">
              <div className="mb-3 mt-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Email
                </label>
                <input
                  type="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="Email"
                  placeholder="Email Address"
                  required
                ></input>
              </div>
              <div className="mb-3 mt-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="pass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                ></input>
              </div>

              <Link to="/forgot">
                <div className="text-left text-primary">Forgot password?</div>
              </Link>

              <button
                type="submit"
                className="btn container text-white mt-5"
                style={{ backgroundColor: "#3AB2D0" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="center">
          <Loader
            type="Audio"
            color="#fff"
            height={100}
            width={100}
            timeout={11000}
          />
        </div>
      )}
    </div>
  );
};

export default Login;
