import React from "react";
import "./Footer.css";
import logo from "../Pages/images/logo.png";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Public from "@material-ui/icons/Public";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="bg-light text-center text-lg-start">
        <div className="text-center p-3 foot">
          <div className="col-lg-12 col-sm-12 mx-auto d-block">
            <div className="row container-fluid">
              <Link className="span-tag mx-auto" to="/about">
                <span className="col-md-4 span-tag">About Us</span>
              </Link>
              <a className="span-tag mx-auto"
                href="https://yourfirstad.com/login/termsAndConditions.html"
                target="_blank"> <span className="col-md-4 span-tag">Terms and Conditions</span></a>

                <a className="span-tag mx-auto"
                href="https://yourfirstad.com/login/privacyPolicy.html"
                target="_blank"> <span className="col-md-4 span-tag">Privacy Policy</span></a>
            </div>
            <br />
            <br />
            <br/>
            <h5 className="text-uppercase">
              <img src={logo} height="60vw" width="40vw"></img>
            </h5>

            <p className="text-center">Revolutionizing Market</p>
            <div className="container">
              <br />
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <a
                    href="https://www.facebook.com/yourFirstAd"
                    target="_blank"
                  >
                    <Facebook></Facebook>{" "}
                  </a>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href="https://www.instagram.com/yourfirstad/"
                    target="_blank"
                  >
                    <Instagram></Instagram>
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href="https://www.linkedin.com/company/yourfirstad/"
                    target="_blank"
                  >
                    <LinkedIn></LinkedIn>
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="https://yourfirstad.com/" target="_blank">
                    <Public></Public>
                  </a>
                </div>
              </div>
              <br />
            </div>
          </div>
          © 2021 Copyright:
          <a className="text-white" href="">
            &nbsp;&nbsp;yourFirstAd.com
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
