import Loader from "react-loader-spinner";
import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import { auth, db } from "../Firebase";
import Nav from "../Navbar/Nav";

import "./UserDashboard.scss";
import Banner from "./Banner/Banner";

const UserDashboard = () => {
  const [userData, setUserData] = useState({});
  const [deals, setDeals] = useState([]);
  const [dealsStatus, setDealsStatus] = useState(true);
  const cardContainer = useRef();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .where("Email", "==", user.email)
          .get()
          .then(async (snapshot) => {
            const DOC = snapshot.docs[0];
            const areas = DOC.data().influencing_Area;
            setUserData({ ...DOC.data(), docID: DOC.id });

            const temp = [];
            for (let i = 0; i < areas.length; i++) {
              db.collection(areas[i])
                .get()
                .then((snapshot) => {
                  snapshot.docs.map((doc) => {
                    temp.push({
                      ...doc.data(),
                      id: doc.id,
                      dealCollectionName: areas[i],
                    });
                  });
                  if (areas.length === 1) {
                    filterDeals(temp, DOC.data().deals || []);
                  } else if (areas.length === 2 && i === 1) {
                    filterDeals(temp, DOC.data().deals || []);
                  }
                });
            }
            setDealsStatus(false);
          });
      }
    });
  }, []);

  const filterDeals = (temp, userDeals) => {
    if (typeof userDeals === typeof undefined) {
      setDeals(temp);
      return;
    }

    const filteredDeals = [];

    temp.map((t) => {
      if (!userDeals.some((d) => t.id === d)) {
        filteredDeals.push(t);
      }
    });

    setDeals([...new Set(filteredDeals)]);
  };

  const generateDOCID = (len) => {
    const letters =
      "bsirndsjrfndnirfn936242905435hjnfwfflnf895923jwejbbfewfjewfwfwee439753iu34h5y3805";

    var str = "";

    for (let i = 0; i < len; i++) {
      str += letters[Math.round(Math.random() * (letters.length - 1))];
    }

    return str;
  };

  const sentDealRequest = (
    dealCollectionName,
    dealDOCID,
    dealName,
    dealImg
  ) => {
    /*
    Have to do  three things :-
    1.) create a new document in requeted-deal
    2.) reference that newly created doc at user's doc and deal's doc
    3.) Admin Nofitications
    4.) WORK DONE

    */

    const randomDocID = generateDOCID(20);

    if (typeof dealImg == typeof undefined) {
      dealImg = "";
    }

    db.collection("requested-deals")
      .doc(randomDocID)
      .set({
        requestedDateTime: new Date().toString(),
        dealType: dealCollectionName,
        userDOCID: userData.docID,
        dealDOCID,
        status: "SENT",
        statusChangedAt: null,
        dealName,
        dealImg,
        userName: userData.Name,
      })
      .then(() => {
        // Deal DOC Updation
        db.collection(dealCollectionName)
          .doc(dealDOCID)
          .get()
          .then((doc) => {
            const usersEnrolled_ = [];
            const temp = doc.data();
            if (!(typeof temp.usersEnrolled === typeof undefined)) {
              usersEnrolled_.push(...temp.usersEnrolled);
            }

            usersEnrolled_.push(randomDocID);
            doc.ref.update({
              usersEnrolled: usersEnrolled_,
            });
          });

        // User DOC Updation
        if (!(typeof userData.requestedDeals === typeof undefined)) {
          userData.requestedDeals.push(randomDocID);
        }

        if (!(typeof userData.deals === typeof undefined)) {
          userData.deals.push(dealDOCID);
        }
        db.collection("users")
          .doc(userData.docID)
          .update({
            requestedDeals: userData.requestedDeals || [randomDocID],
            deals: userData.deals || [dealDOCID],
          });

        //Admin Nofitications
        db.collection("admin-notifications")
          .doc()
          .set({
            userDOCID: `users->${userData.docID}`,
            dealRef: `${dealCollectionName}->${dealDOCID}`,
            dealType: "normal",
            timestamp: new Date().toString(),
          })
          .then(() => console.log("notification generated"))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className=""
      style={{
        backgroundColor: `${
          dealsStatus || deals.length === 0 ? "#fff" : "#C8E9F9"
        }`,
        width: "100%",
      }}
    >
      <Banner />
      <Nav />
      {dealsStatus ? (
        <div
          className="msg"
          style={{ marginLeft: "50rem", marginTop: "25rem" }}
        >
          <Loader
            type="Audio"
            color="#3AB2D0"
            height={100}
            width={100}
            timeout={11000}
          />
        </div>
      ) : deals.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: "20px",
            backgroundColor: "#ebf9fc",
          }}
          className="msg"
        >
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#ebf9fc",
              width: "100%",
            }}
          >
            LIVE DEALS
          </h2>
          <h4 style={{ textAlign: "center", marginTop: "20px" }}>
            No Live Deals, Please check it in a while :)
          </h4>
        </div>
      ) : (
        <React.Fragment>
          <h2 style={{ textAlign: "center" }}>LIVE DEALS</h2>

          <div
            className="row row-cols-1 row-cols-md-3 g-4 container mx-auto "
            ref={cardContainer}
          >
            {deals.map(({ ...deal }) => (
              <div key={deal.id} className="row" id={`card-${deal.id}`}>
                <div class="card mb-3 deal-card" style={{ maxWidth: "540rem" }}>
                  <div className="row g-0" style={{ margin: "0.8rem" }}>
                    <div className="col-md-4" style={{ paddingTop: "30px" }}>
                      <img className="img-fluid" src={deal.imgUrl} alt="" />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">
                          <strong>Deal Name:- </strong>
                          {deal.taskName}
                        </h5>
                        <br />
                        <h8 className="card-text">
                          <strong>Task Description:-</strong> {deal.description}
                        </h8>
                        <br />
                        <br />
                        <h7 className="card-text">
                          <strong>Task Deadline:-</strong> {deal.deadline}
                        </h7>
                        <br />
                        <br />
                        <h6 className="card-text">
                          <strong>Reward:- {deal.reward}</strong>
                        </h6>
                      </div>
                      <button
                        className="btn m-5"
                        style={{ backgroundColor: "#3AB2D0", width: "50%" }}
                        onClick={(E) => {
                          swal({
                            text: "Are you sure?",
                            buttons: {
                              cancel: true,
                              confirm: true,
                            },
                          }).then((status) => {
                            if (status === true) {
                              sentDealRequest(
                                deal.dealCollectionName,
                                deal.id,
                                deal.taskName || "",
                                deal.imgUrl
                              );

                              E.target.disabled = true;

                              document.querySelector(
                                `#card-${deal.id}`
                              ).style.display = "none";

                              swal({
                                closeOnClickOutside: true,
                                icon: "success",
                                button: false,
                                timer: 1000,
                              });
                            }
                          });
                        }}
                      >
                        Request
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default UserDashboard;
